import createRequestValidationForm from "@serie3/common/Form/Register/RequestValidationForm";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContainer from "./AuthContainer";
import TextField from "../../Form/TextField";
import Header from "../Landingpage/Subcomponents/Header";

const RequestValidationLink = ({ currentLanguage, setCurrentLanguage }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const requestValidationForm = createRequestValidationForm(setShowSuccess);
  const formik = useFormik(requestValidationForm);

  const { t } = useTranslation();

  return (
    <Header
      currentLanguage={currentLanguage}
      setCurrentLanguage={setCurrentLanguage}
    >
      <AuthContainer
        title={t("Login.Request Verify Link.Title")}
        description={t("Login.Request Verify Link.Subtitle")}
      >
        <TextField
          formik={formik}
          name={"email"}
          valueName={"email"}
          placeholder={t("Login.Request Verify Link.Placeholder")}
        />

        <div className="d-grid mt-5">
          <button
            className="btn btn-info w-100"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {t("Login.Request Verify Link.Button")}
          </button>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <Link to={"/login"} className="btn-link text-decoration-none">
            {t("Login.Login")}
          </Link>
          <Link to={"/signup"} className="btn-link text-decoration-none">
            {t("Login.New Account")}
          </Link>
        </div>
        <div className="align-items-center text-center border-top pt-3 mt-3">
          {showSuccess ? (
            <span style={{ color: "green" }}>
              {t("Login.Messages.ValidationMailSend")}{" "}
            </span>
          ) : (
            ""
          )}
        </div>
      </AuthContainer>
    </Header>
  );
};

export default RequestValidationLink;
