import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col } from "react-bootstrap";

const FeatuerBox = ({ image, title, text }) => {
  const { t } = useTranslation();
  return (
    <div className="featureBox">
      <p>
        <img src={image} style={{ width: "84px", height: "84px" }} />
      </p>
      <h3 style={{textAlign: "left"}}>{title}</h3>
      <p style={{textAlign: "left"}}>
        {text}
      </p>
    </div>
  );
};

export default FeatuerBox;
