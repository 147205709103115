import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import blogclient from "@serie3/common/api/blogClient";
import getSettings from "@serie3/common/config/settings";
import { useLocation } from "react-router-dom";
import Header from "../frontend/Landingpage/Subcomponents/Header";

const Blogpost = ({currentLanguage, setCurrentLanguage}) => {
  const { splat } = useParams();
  const [blogContent, setBlogContent] = useState("");
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");
  const [headerImage, setHeaderImage] = useState(null);
  const [description, setDescription] = useState(null);
  const settings = getSettings();

  const { pathname } = useLocation();

  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get(splat);
      if (response.status === 200) {
        let content = response.data.content.colPos0[0].content.bodytext;
        let publicUrl =
          response.data.content.colPos0[0].content.gallery.rows[1].columns[1]
            .publicUrl;
        let parsedContent = content.replace(
          'src="/fileadmin/',
          'src="' + settings.blogUrl + "/fileadmin/"
        );
        setHeader(response.data.meta.title);
        setBlogContent(parsedContent);
        setHeaderImage(publicUrl);
        setSubHeader(response.data.meta.subtitle);
        setDescription(response.data.meta.description);
        window.scrollTo(0, 0);
      }
    };
    loadData();
  }, [pathname]);

  const containerBackground = {
    // Combine gradient and image in the background property
    background: `linear-gradient(90deg, rgba(36, 36, 36, 0.75) 0%, rgba(36, 36, 36, 0.00) 100%), url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return headerImage ? (
    <>
      <Header
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      >
        <h2>{header}</h2>
        <p>{subHeader}</p>
      </Header>
      <Container>
        <Row>
          <Col md={9} className="blogpost">
            <div dangerouslySetInnerHTML={{ __html: blogContent }} />
          </Col>
        </Row>
      </Container>
    </>
  ) : null;
};
export default Blogpost;
