import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import MessagesEndpoints from "@serie3/mymediaplanner/Modules/Messages/endpoints";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { isCurrentMonthAndYear } from "@serie3/common/Shared/timeingStuff";
import NewModal from "../../../shared/Modals/NewModal";
import CompleteDropdown from "../../../shared/Dropdown/CompleteDropdown";
import ConnectionRequestForm from "@serie3/mymediaplanner/Modules/Social/Forms/ConnectionRequestForm";
import { useFormik } from "formik";
import ConnectionRequest from "@serie3/mymediaplanner/Modules/Social/Entity/ConnectionRequest";
import TextField from "../../../Form/TextField";
import TextArea from "../../../Form/TextArea";
import NumberField from "../../../Form/NumberField";
import SaveButton from "../../../shared/Buttons/SaveButton";
import Checkbox from "../../../Form/Checkbox";
import listHandler from "@serie3/common/Domain/listHandler";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";

const Requests = () => {
  const { t } = useTranslation();
  const [requests, setRequests] = useState([]);
  const [sendThisMonth, setSendThisMonth] = useState([]);
  const [acceptanceRate, setAcceptanceRate] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editRequest, setEditRequest] = useState();

  useEffect(() => {
    const fetchRequests = async () => {
      await crudApi.getAll(
        MessagesEndpoints.getSentRequests,
        setRequests,
        "requests"
      );
    };
    fetchRequests();
  }, []);

  useEffect(() => {
    let monthCount = 0;
    let rejected = 0;
    let accepted = 0;

    requests.forEach((item) => {
      if (isCurrentMonthAndYear(item.send_date)) {
        monthCount++;
      }
      if (item.withdrawn) {
        rejected++;
      } else {
        accepted++;
      }
    });
    let acceptanceRate = 100 - Math.round((rejected / requests.length) * 100);
    setSendThisMonth(monthCount); // Update state after all items have been processed
    setAcceptanceRate(acceptanceRate);
  }, [requests]);

  const editConnectionRequestForm =
    ConnectionRequestForm.createConnectionRequestForm(
      requests,
      setRequests,
      setShowEdit
    );

  const formik = useFormik(editConnectionRequestForm);

  const Save = () => {
    return <SaveButton formik={formik} />;
  };
  return (
    <>
      <NewModal
        show={showEdit}
        title={t("Backend.Outreach.Connections.Requests.Modal.Title")}
        setShow={setShowEdit}
        onShow={() => {
          editRequest
            ? formik.setValues(ConnectionRequestForm.setEditData(editRequest))
            : formik.setValues(ConnectionRequest);
        }}
        onExit={() => {
          formik.resetForm({ values: { ConnectionRequest } });
        }}
        ExtraButton={Save}
      >
        <Row className="mb-1">
          <Col>
            <TextField
              formik={formik}
              valueName={"name"}
              label={t("Backend.Outreach.Connections.Requests.Modal.Name")}
            />
          </Col>
        </Row>
        <Row className="mb-1">
          <Col>
            <NumberField
              formik={formik}
              valueName={"follower"}
              step="1"
              label={t(
                "Backend.Outreach.Connections.Requests.Modal.Followercount"
              )}
            />
          </Col>
        </Row>
        <Row className="mb-1">
          <Col>
            <TextArea
              formik={formik}
              valueName={"bio"}
              label={t("Backend.Outreach.Connections.Requests.Modal.Bio")}
            />
          </Col>
        </Row>
        <Row className="mb-1">
          <Col>
            <Checkbox
              formik={formik}
              valueName={"withdrawn"}
              label={t("Backend.Outreach.Connections.Requests.Accepted")}
            />
          </Col>
        </Row>
      </NewModal>

      <h4 className="mb-sm-0">
        {t("Backend.Outreach.Connections.Requests.Title")}
      </h4>
      <Row className="mt-3">
        <Col md={4}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-18">
                      <i className="uil uil-list-ul"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1 text-truncate text-muted">
                    {t(
                      "Backend.Outreach.Connections.Requests.Stats.TotalRequests"
                    )}
                  </p>
                  <h5 className="font-size-16 mb-0">{requests.length}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-18">
                      <i className="uil uil-list-ul"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1 text-truncate text-muted">
                    {t(
                      "Backend.Outreach.Connections.Requests.Stats.TotalAcceptanceRate"
                    )}
                  </p>
                  <h5 className="font-size-16 mb-0">{acceptanceRate} %</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-18">
                      <i className="uil uil-list-ul"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1 text-truncate text-muted">
                    {t(
                      "Backend.Outreach.Connections.Requests.Stats.SendThisMonth"
                    )}
                  </p>
                  <h5 className="font-size-16 mb-0">{sendThisMonth}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <div className="table-responsive react-table">
                <table className="custom-header-css table table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Name</th>
                      <th style={{ textAlign: "center" }}>
                        {t("Backend.Outreach.Connections.Requests.Accepted")}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{formatDate(item.send_date)}</td>
                          <td>
                            <a
                              href={item.url}
                              class="text-decoration-none"
                              target="_blank"
                            >
                              {item.name}
                              &nbsp;
                              {item.follower > 0 && (
                                <small class="text-muted">
                                  ({item.follower})
                                </small>
                              )}
                            </a>
                            <p
                              class="mb-0 mt-1 small"
                              dangerouslySetInnerHTML={{
                                __html: item.bio && item.bio,
                              }}
                            />
                          </td>
                          <td style={{ textAlign: "center", color: "red" }}>
                            {item.withdrawn && <FontAwesomeIcon icon={faX} />}
                          </td>
                          <td>
                            <CompleteDropdown
                              editAction={() => {
                                setEditRequest(item);
                                setShowEdit(true);
                              }}
                              deleteAction={() => {
                                listHandler.deleteFromList(
                                  index,
                                  crudApi.del(
                                    SocialEndpoints.connections.delete,
                                    item.id
                                  ),
                                  setRequests,
                                  requests
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Requests;
