import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faThumbsUp,
  faComment,
} from "@fortawesome/free-solid-svg-icons";


const Interactions = ({ views, likes, comments, viewColor = '#495057', likesColor= '#495057', commentsColor = '#495057', size="small" }) => {
  const { t } = useTranslation();

  return (
    <span style={{fontSize: size}}>
      <span style={{ color: viewColor }}>
        <FontAwesomeIcon icon={faEye} className="me-1" />
        {views}
        </span>
        <span style={{  color: viewColor }}>
        <FontAwesomeIcon icon={faThumbsUp} className="ms-3 me-1" />
        {likes}
        </span>
        <span style={{color: viewColor }}>
        <FontAwesomeIcon icon={faComment} className="ms-3 me-1" />
        {comments}
      </span>
    </span>
  );
};

export default Interactions;
