import { Routes, Route } from "react-router-dom";
import ContentenPillars from "./ContentPillars";

const ContentPillarsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContentenPillars />} />
    </Routes>
  );
};

export default ContentPillarsRouter;
