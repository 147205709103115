import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import TextField from "../../../../Form/TextField";
import TextArea from "../../../../Form/TextArea";

const ContentPillarFormView = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="mb-3">
        <Col>
          <TextField
            label={t("Backend.Post.ContentPillars.Pillar")}
            formik={formik}
            value={formik.pillar}
            valueName={"pillar"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            label={t("Backend.Post.ContentPillars.PillarDescription")}
            formik={formik}
            value={formik.description}
            valueName={"description"}
            rows={15}
          />
        </Col>
      </Row>
    </>
  );
};

export default ContentPillarFormView;
