import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import ContentPillarTimeline from "./Subcomponents/ContentPillarTimeline";
import crudApi from "@serie3/common/api/crudApi";
import { useState, useEffect } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import NewModal from "../../../shared/Modals/NewModal";
import ContentPillarFormView from "./Subcomponents/ContentPillarFormView";
import NewButtonWithIcon from "../../../shared/Buttons/NewButtonWithIcon";
import { useFormik } from "formik";
import ContentPillarForm from "@serie3/mymediaplanner/Modules/Social/Forms/ContentPillarForm";
import ContentPillar from "@serie3/mymediaplanner/Modules/Social/Entity/ContentPillar";
import PostFormView from "../PostFormView";

const ContentPillars = ({ props }) => {
  const { t } = useTranslation();

  const [pillars, setPillars] = useState([]);
  const [show, setShow] = useState(false);
  const [editPillar, setEditPillar] = useState([]);
  const [posts, setPosts] = useState([]);
  const [showPostForm, setShowPostForm] = useState(false);
  const [editPost, setEditPost] = useState(null);
  const [trigger, setTrigger] = useState(-1);

  const pillarForm = ContentPillarForm.createContentPillarForm(
    pillars,
    setPillars
  );
  const formik = useFormik(pillarForm);

  useEffect(() => {
    const fetchPillars = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.contentPillars.index,
        setPillars,
        "contentpillars"
      );
    };
    fetchPillars();
  }, []);

  const AddBtn = () => {
    return (
      <>
        <NewButtonWithIcon
          show={show}
          handleClick={() => setShow(true)}
          title={t("Backend.Post.ContentPillars.New")}
        />
      </>
    );
  };

  const editAction = (pillar) => {
    setEditPillar(pillar);
    setShow(true);
  };

  return (
    <>
      <NewModal
        title={t("Backend.Post.ContentPillars.Modal.Title")}
        show={show}
        setShow={setShow}
        onShow={() => {
          editPillar
            ? formik.setValues(ContentPillarForm.setEditData(editPillar))
            : formik.setValues(ContentPillar);
        }}
        onExit={() => {
          formik.resetForm();
        }}
        ExtraButton={() => {
          return (
            <Button onClick={() => formik.submitForm()}>
              {t("Backend.Buttons.Save")}
            </Button>
          );
        }}
      >
        <ContentPillarFormView formik={formik} />
      </NewModal>

      <PostFormView
        showEdit={showPostForm}
        setShowEdit={setShowPostForm}
        editData={editPost}
        dataList={posts}
        setDataList={setPosts}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      <h4 className="mb-3">{t("Backend.Post.ContentPillars.Title")}</h4>
      <Row>
        <Col className="page-title-box d-flex align-items-center justify-content-end">
          <AddBtn />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="page-title-box d-flex align-items-center justify-content-between"></div>
        </Col>
      </Row>

      {pillars.map((pillar, index) => {
        return (
          <ContentPillarTimeline
            pillar={pillar}
            key={pillar.id}
            index={index}
            pillars={pillars}
            setPillars={setPillars}
            editAction={editAction}
            setEditPostList={setPosts}
            setEditPost={setEditPost}
            setShowPostForm={setShowPostForm}
            trigger={trigger}
          />
        );
      })}
    </>
  );
};

export default ContentPillars;
