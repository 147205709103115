import { useTranslation } from "react-i18next";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { useState } from "react";
import OpenArrow from "../../../../assets/images/landingpage/openArrow.svg";
import ClosedArrow from "../../../../assets/images/landingpage/closedArrow.svg";
import ChartIcon from "../../../../assets/images/landingpage/Icons/chartIcon.svg";
import HeartIcon from "../../../../assets/images/landingpage/Icons/heartIcon.svg";
import MusicNoteIcon from "../../../../assets/images/landingpage/Icons/musicnoteIcon.svg";
import ChecklistIcon from "../../../../assets/images/landingpage/Icons/checklistIcon.svg";

const AccordionFeature = ({ props }) => {
  const [openKey, setOpenKey] = useState(null); // Tracks which accordion item is open
  const { t } = useTranslation();

  function CustomToggle({ children, icon, title, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      handleClick(eventKey); // this toggles the accordion
    });

    let className = openKey === eventKey ? "cardOpen" : "";
    let imgSrc = openKey === eventKey ? OpenArrow : ClosedArrow;

    return (
      <Card
        onClick={decoratedOnClick}
        className={className}
        style={{ cursor: "pointer" }}
      >
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={icon}
              alt="Icon"
              style={{ height: "30px", width: "30px", marginRight: "10px" }} // Add margin for spacing
            />
            <span>{title}</span>
          </div>
          <img
            src={imgSrc}
            alt="Toggle Icon"
            style={{ height: "24px", width: "24px" }}
          />
        </Card.Header>

        {children}
      </Card>
    );
  }

  const handleAccordionClick = (key) => {
    if (key === openKey) {
      setOpenKey(null); // If the same key is clicked, close the accordion
    } else {
      setOpenKey(key); // Set the new key as the open key
    }
  };

  return (
    <Accordion>
      <CustomToggle
        eventKey="1"
        title={t("Landingpage.FeatureVideo.Goals.Title")}
        icon={ChecklistIcon}
        handleClick={handleAccordionClick}
      >
        <Accordion.Collapse eventKey="1">
          <Card.Body>{t("Landingpage.FeatureVideo.Goals.Text")}</Card.Body>
        </Accordion.Collapse>
      </CustomToggle>
      <CustomToggle
        eventKey="2"
        title={t("Landingpage.FeatureVideo.Stats.Title")}
        icon={ChartIcon}
        handleClick={handleAccordionClick}
      >
        <Accordion.Collapse eventKey="2">
          <Card.Body>{t("Landingpage.FeatureVideo.Stats.Text")}</Card.Body>
        </Accordion.Collapse>
      </CustomToggle>
      <CustomToggle
        eventKey="3"
        title={t("Landingpage.FeatureVideo.Recycling.Title")}
        icon={MusicNoteIcon}
        handleClick={handleAccordionClick}
      >
        <Accordion.Collapse eventKey="3">
          <Card.Body>{t("Landingpage.FeatureVideo.Recycling.Text")}</Card.Body>
        </Accordion.Collapse>
      </CustomToggle>
      <CustomToggle
        eventKey="4"
        title={t("Landingpage.FeatureVideo.Assets.Title")}
        icon={MusicNoteIcon}
        handleClick={handleAccordionClick}
      >
        <Accordion.Collapse eventKey="4">
          <Card.Body>{t("Landingpage.FeatureVideo.Assets.Text")}</Card.Body>
        </Accordion.Collapse>
      </CustomToggle>
      <CustomToggle
        eventKey="0"
        title={t("Landingpage.FeatureVideo.CustomContent.Title")}
        icon={HeartIcon}
        handleClick={handleAccordionClick}
      >
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {t("Landingpage.FeatureVideo.CustomContent.Text")}
          </Card.Body>
        </Accordion.Collapse>
      </CustomToggle>
    </Accordion>
  );
};

export default AccordionFeature;
