import { useTranslation } from "react-i18next";

const UserProof = ({name, picture, url, title}) => {
  const { t } = useTranslation();
  return (
    <div className="userProof">
        <img src={picture} />
        <h5 style={{marginBottom: "0px"}}>{name}</h5>
        <span><a href={url} target="_top"/>{title}</span>
    </div>
  )
};

export default UserProof;
