import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeroImage from "../../assets/images/landingpage/heroImage.png";
import Header from "./Landingpage/Subcomponents/Header";
import SocialProof from "./Landingpage/Subcomponents/SocialProof";
import TargetGroups from "./Landingpage/Subcomponents/TargetGroups";
import FeatureVideo from "./Landingpage/Subcomponents/FeatureVideo";
import BlogCard from "../blog/BlogCard";
import blogclient from "@serie3/common/api/blogClient";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Landingpage = ({ currentLanguage, setCurrentLanguage }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hash === "#features") {
      const element = document.getElementById("features");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    const loadData = async () => {
      const response = await blogclient.get();
      if (response.status === 200) {
        setBlogPosts([...response.data.subpages]);
      }
    };
    loadData();
  }, []);

  return (
    <>
      <Header
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      >
        <Row className="justify-content-between">
          <Col md={5} xs={12} className="mb-5">
            <h1 className="heroHeader">{t("Landingpage.HeroHeader")}</h1>
            <div className="mb-4">
            {t("Landingpage.HeroText")}
            </div>
            <button
              style={{
                textTransform: "capitalize",
              }}
              className="actionButton"
              onClick={() => navigate('/signup')}
            >
              {t("Landingpage.HeroButton")}
            </button>
          </Col>
          <Col md={6} xs={12}>
            <img src={HeroImage} style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
      </Header>
      <SocialProof />
      <TargetGroups />
      <FeatureVideo />
      <Container style={{ marginBottom: "120px" }}>
        <h3 style={{ marginBottom: "60px" }}>{t("Landingpage.Blog.Title")}</h3>
        <Row>
          {blogPosts.map((post, index) => {
            return <BlogCard size={6} post={post} key={index} />;
          })}
        </Row>
      </Container>
    </>
  );
};

export default Landingpage;
