import { initializeApp } from "firebase/app";
import firebaseConfig from "@serie3/mymediaplanner/Config/Firebase/firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContainer from "./AuthContainer";
import Header from "../Landingpage/Subcomponents/Header";

const Forgot = ({currentLanguage, setCurrentLanguage}) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const emailFieldRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation();

  const resetPassword = () => {
    const email = emailFieldRef.current.value;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setShowSuccess(true);
      })
      .catch((error) => {
        setShowError(true);
      });
  };
  return (
    <Header
    currentLanguage={currentLanguage}
    setCurrentLanguage={setCurrentLanguage}
  >
      <AuthContainer
        title={t("Login.Forgot.Title")}
        description={
          "      Please enter the email address associated with your account and we will send you a link to reset your password."
        }
      >
        <div className="mt-4">
          <Form.Control
            placeholder={t("Login.Forgot.Placeholder")}
            type={"text"}
            ref={emailFieldRef}
          />

          <div className="d-grid mt-5">
            <button
              className="btn btn-info w-100"
              type="submit"
              onClick={() => {
                setShowError(false);
                resetPassword();
              }}
            >
              {t("Login.Forgot.Button")}
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <Link to={"/login"} className="btn-link text-decoration-none">
            {t("Login.Login")}
          </Link>
          <Link to={"/signup"} className="btn-link text-decoration-none">
            {t("Login.New Account")}
          </Link>
        </div>
        <div className="align-items-center justify-content-between text-center border-top pt-3 mt-3">
          {showError ? (
            <span style={{ color: "red" }}> {t("Login.Messages.Error")} </span>
          ) : (
            ""
          )}
          {showSuccess ? (
            <span style={{ color: "green" }}>
              {t("Login.Messages.Success")}{" "}
            </span>
          ) : (
            ""
          )}
        </div>
      </AuthContainer>
    </Header>
  );
};
export default Forgot;
