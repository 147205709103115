import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import Interactions from "../../shared/Interactions";
import { useTranslation } from "react-i18next";

const CustomEvent = ({ event, setViewId, deletePlan }) => {
  const { t } = useTranslation();
  let borderColor = "#3174ad"; // Default color

  // Check if event has certain types
  if (event.status.includes("error")) {
    borderColor = "#f56c6c"; // Red for meetings
  }
  if (event.status.includes("scheduled")) {
    borderColor = "#e6a23c"; // Orange for important events
  }
  if (event.status.includes("published")) {
    borderColor = "#67c23a"; // Green for reviews
  }

  return (
    <div
      style={{
        borderRadius: "5px",
        position: "relative",
        borderLeft: `10px solid ${borderColor}`,
        minHeight: "50px",
      }}
    >
      {/* Left border to indicate status */}
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          width: "6px",
          backgroundColor: event.statusColor, // Color based on status
        }}
      ></div>

      {/* Event content */}
      <div style={{ padding: "10px" }}>
        <Row>
          <Col
            md={9}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <strong
              onClick={() => {
                setViewId(event);
              }}
            >
              {event.title}
            </strong>
          </Col>
          <Col>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => {
                deletePlan(event);
              }}
              style={{ float: "right", color: "red" }}
            />
          </Col>
        </Row>
        <div
          onClick={() => {
            setViewId(event);
          }}
        >
          <Row style={{ fontSize: "0.8em", color: "#888" }}>
            <Col>{formatDate(event.postDate)}</Col>
          </Row>
          <Row style={{ fontSize: "0.8em", color: "#888" }}>
            <Col>{event.timezone}</Col>
          </Row>

          <Row>
            <Col>
              {event.draft && (
                <span
                  key="ready"
                  className="badge-danger-subtle font-size-12 badge bg-link rounded-pill me-3"
                >
                  {t("Backend.Post.State.Draft")}
                </span>
              )}
              {event.planning &&
                (!event.id ? (
                  <span
                    key="ready"
                    className="badge-warning-subtle font-size-12 badge bg-link rounded-pill me-3"
                  >
                    {t("Backend.Post.State.Recommendation")}
                  </span>
                ) : (
                  <span
                    key="ready"
                    className="badge-success-subtle font-size-12 badge bg-link rounded-pill me-3"
                  >
                    {t("Backend.Post.State.Premade")}
                  </span>
                ))}
            </Col>
          </Row>

          {event.status.includes("published") && (
            <Row className="mt-2">
              <Col>
                <Interactions
                  views={event.stats?.views ?? 0}
                  likes={event.stats?.likes ?? 0}
                  comments={event.stats?.comments ?? 0}
                  size="smaller"
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomEvent;
