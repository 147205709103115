import Schedule from "../Entity/Schedule";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createScheduleForm = (
  dataList,
  setDataFunction,
  setAdditionalData,
  additonalDataList,
  postId
) => {
  const onSubmit = async (values) => {
    const originalValues = { ...values };
    if (values.scheduled_for == "") {
      delete values.scheduled_for;
    }
    if (values.id > 0) {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        SocialEndpoints.posts.schedule.edit,
        payload
      );

      let originalPayload = JSON.stringify(originalValues, null, 2);
      listHandler.updateList(
        response,
        originalPayload,
        dataList,
        setDataFunction
      );

      listHandler.replaceItemInList(
        response.data,
        additonalDataList,
        setAdditionalData
      );
    } else {
      if (typeof postId !== "undefined") {
        values.post.id = postId;
      }
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        SocialEndpoints.posts.schedule.new,
        payload
      );

      listHandler.newToList(response, dataList, setDataFunction, null);

      additonalDataList &&
        setAdditionalData &&
        listHandler.newToList(
          response,
          additonalDataList,
          setAdditionalData,
          null
        );
    }
  };

  return {
    initialValues: Schedule,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Schedule = {
    id: editData.id,
    local_user_time: editData.local_user_time,
    platform: editData.platform,
    timezone: editData.timezone,
    post: { id: editData.post.id },
  };

  return Schedule;
};

export default { createScheduleForm, setEditData };
