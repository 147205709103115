import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import AccordionFeature from "./AccordionFeature";
import FeatureImage from "../../../../assets/images/landingpage/featureimage.jpg";

const FeatureVideo = ({ props }) => {
  const { t } = useTranslation();
  return (
    <Container id="featureVideos">
      <h3>{t("Landingpage.FeatureVideo.Title")}</h3>
      <p style={{ textAlign: "center" }}>{t("Landingpage.FeatureVideo.Text")}</p>
      <Row
        className="gx-5"
        style={{
          marginTop: "60px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Col md={6} id="features">
          <AccordionFeature />
        </Col>
        <Col md={5} className="hiddenMobile">
          <img src={FeatureImage} style={{ maxWidth: "100%" }} />
        </Col>
      </Row>
    </Container>
  );
};

export default FeatureVideo;
