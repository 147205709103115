import { useTranslation } from "react-i18next";
import Header from "../Landingpage/Subcomponents/Header";

const Pricing = ({currentLanguage, setCurrentLanguage}) => {
  const { t } = useTranslation();

  return (
    <Header
    currentLanguage={currentLanguage}
    setCurrentLanguage={setCurrentLanguage}
  >
      <h3>We're currently in Beta - It's free if you sign up till the 30.06.2025</h3>
      <h5 style={{textAlign: "center"}}>Contact Felix Dziekan on LinkedIn to get access to the BetaTester Whatsapp group and get free lifetime access</h5>
    </Header>
  );
};

export default Pricing;
