import { useTranslation } from "react-i18next";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import CompleteDropdown from "../../../../shared/Dropdown/CompleteDropdown";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faThumbsUp,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Interactions from "../../../../shared/Interactions";

const Slider = ({
  post,
  setEditPost,
  setShowEdit,
  posts,
  setPosts,
  index,
  averageComments,
  averageLikes,
  averageViews,
}) => {
  const { t } = useTranslation();
  const [colors, setColors] = useState({
    likeColor: "#f7cc53",
    viewColor: "#f7cc53",
    commentColor: "#f7cc53",
  });

  /** 15% deviation from the Average still counts as average */
  let averageViewsDeviation = averageViews * 0.15;
  let averageLikeDeviation = averageLikes * 0.15;
  let averageCommentsDeviation = averageComments * 0.15;

  useEffect(() => {
    let viewColor;
    let likeColor;
    let commentColor;

    if (post.stats?.[0]) {
      const { views } = post.stats?.at(-1);
      const lowerBound = averageViews - averageViewsDeviation;
      const upperBound = averageViews + averageViewsDeviation;

      viewColor =
        views > upperBound
          ? "#07bc0c"
          : views >= lowerBound
          ? "black"
          : "#ff2d55";
    }

    if (post.stats?.[0]) {
      const { likes } = post.stats?.at(-1);
      const lowerBound = averageLikes - averageLikeDeviation;
      const upperBound = averageLikes + averageLikeDeviation;

      likeColor =
        likes > upperBound
          ? "#07bc0c"
          : likes >= lowerBound
          ? "black"
          : "#ff2d55";
    }

    if (post.stats?.[0]) {
      const { comments } = post.stats?.at(-1);
      const lowerBound = averageComments - averageCommentsDeviation;
      const upperBound = averageComments + averageCommentsDeviation;

      commentColor =
        comments > upperBound
          ? "#07bc0c"
          : comments >= lowerBound
          ? "black"
          : "#ff2d55";
    }

    setColors({
      viewColor: viewColor,
      likeColor: likeColor,
      commentColor: commentColor,
    });
  }, []);

  return (
    <div className="event-list">
      <h5
        className="font-size-14 text-primary mb-0"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {post.title}

        <CompleteDropdown
          deleteAction={() => {
            listHandler.deleteFromList(
              index,
              crudApi.del(
                SocialEndpoints.posts.contentPillars.deleteFromPillar,
                post.id
              ),
              setPosts,
              posts
            );
          }}
          editAction={() => {
            setEditPost(post);
            setShowEdit(true);
          }}
          deleteTitle={t("Backend.Post.ContentPillars.RemoveFromPillar")}
        />
      </h5>
      <p
        className="font-size-10 mb-3"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>{formatDate(post.last_edit)}</span>
        <span>
          {post &&
            post.stats &&
            post.stats.length > 0 &&
            (() => {
              // Logging statement
              return (
                <>
                  <Interactions
                    views={post.stats?.at(-1).views}
                    likes={post.stats?.at(-1).likes}
                    comments={post.stats?.at(-1).comments}
                    viewColor={colors.viewColor}
                    likesColor={colors.likeColor}
                    commentsColor={colors.commentColor}
                  />
                </>
              ); // Return the actual value to be rendered
            })()}
        </span>
      </p>
      <p className="text-muted truncate-two-lines">{post.text}</p>
      <p className="text-muted">
        &nbsp;
        <span
          className={`badge me-3 font-size-12 badge bg-link rounded-pill badge-info-subtle`}
        >
          {post.content_type}
        </span>
        <span
          className={`badge me-3 font-size-12 badge bg-link rounded-pill badge-info-subtle`}
        >
          {post.post_type}
        </span>
        <span
          className={`badge me-3 font-size-12 badge bg-link rounded-pill badge-info-subtle`}
        >
          {post.status}
        </span>
      </p>
    </div>
  );
};

export default Slider;
