import { Dropdown, Navbar, Nav, Button } from "react-bootstrap";
import i18n from "../../i18n.js";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import CustomToggle from "../shared/Dropdown/CustomToggle.js";

const HeaderNavbar = ({ currentLanguage, setCurrentLanguage }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(i18n.language);
  };

  return (
    <Navbar
      dark
      expand="lg"
      style={{
        padding: "20px 20px 20px 30px",
        backgroundColor: "F3F8FF",
        marginTop: "20px",
        color: "black",
        border: "1px solid",
        borderRadius: "70px",
        border: "1px solid rgba(83, 97, 119, 0.20)",
        background: "#F3F8FF",
        marginBottom: "100px",
      }}
    >
      <Navbar.Brand href="/" style={{ fontWeight: "bold", color: "red" }}>
        Mymediaplanner
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          className="mx-auto"
          color="black"
          style={{
            color: "var(--Color-black, #232933)",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          <Nav.Link
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              navigate("/pricing");
            }}
          >
            Pricing
          </Nav.Link>
          <Nav.Link href="/#features">Features</Nav.Link>
          <Nav.Link href="/blog">Tipps and Tricks</Nav.Link>
          {/*<Nav.Link href="#features">Starter</Nav.Link>
          <Nav.Link href="#resources">Teams</Nav.Link>
          <Nav.Link href="#aboutus">Writers/Coaches</Nav.Link>
          <Nav.Link href="#aboutus">Find Expert</Nav.Link>
          <Nav.Link href="#pricing">Tipps</Nav.Link>*/}
        </Nav>
        <Button
          variant="outline-primary"
          style={{ marginRight: "10px", border: "none" }}
          onClick={() => {
            navigate("/login");
          }}
        >
          Log In
        </Button>
        <div className="btn-group">
          <Button
            variant="primary"
            style={{
              borderBottomLeftRadius: "50px",
              borderTopLeftRadius: "50px",
              border: "1px solid var(--Color-skyblue, #5FD0F3)",
              background: "var(--Color-skyblue, #5FD0F3)",
            }}
            onClick={() => {
              navigate("/signup");
            }}
          >
            Sign Up
          </Button>
          <Dropdown
            style={{
              float: "right",
              marginLeft: "-5px",
            }}
          >
            <Dropdown.Toggle
              id="dropdown-basic"
              as={CustomToggle}
              style={{ border: "none", boxShadow: "none" }}
            />

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeLanguage("de")}>
                <span className="fi fi-de fis" />
                &nbsp; {t("Translation.German")}
              </Dropdown.Item>
              <div className="dropdown-divider" />
              <Dropdown.Item onClick={() => changeLanguage("en")}>
                <span className="fi fi-us fis" />
                &nbsp; {t("Translation.English")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HeaderNavbar;
