import { Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import blogclient from "@serie3/common/api/blogClient";
import Header from "../frontend/Landingpage/Subcomponents/Header";

const BlogList = ({ currentLanguage, setCurrentLanguage }) => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get();
      if (response.status === 200) {
        setBlogPosts([...response.data.subpages]);
      }
    };
    loadData();
  }, []);

  return (
    <>
      <Header
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      >
        <Row>
          {blogPosts.map((post, index) => {
            return <BlogCard size={6} post={post} key={index} />;
          })}
        </Row>
      </Header>
      <Container>
        <Row>
          {blogPosts.map((post, index) => {
            return <BlogCard size={4} post={post} key={index} />;
          })}
        </Row>
      </Container>
    </>
  );
};

export default BlogList;
