const AiPlanningAssistant = {
    month: '',
    interval: 3,
    use_posts: true,
    pillars: [],
    days: [],
    timezone: ''
};

export default AiPlanningAssistant;
