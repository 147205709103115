import { useTranslation } from "react-i18next";
import PostCalendar from "../PostCalendar";
import { useState, useEffect } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";

const CalendarWrapper = () => {
  const { t } = useTranslation();
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.schedule.index,
        setSchedules,
        SocialEndpoints.posts.scheduledDataField
      );
    };

    fetchPosts();
  }, []);

  return <PostCalendar schedules={schedules} setSchedules={setSchedules}/>;
};

export default CalendarWrapper;
