import TextField from "../../Form/TextField";
import { useTranslation } from "react-i18next";
import createRegisterForm from "@serie3/common/Form/Register/RegisterForm";
import { useState } from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContainer from "./AuthContainer";
import { UilEnvelope, UilUser, UilLock } from "@iconscout/react-unicons";
import Header from "../Landingpage/Subcomponents/Header";

const Signup = ({ currentLanguage, setCurrentLanguage }) => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showProcess, setShowProcess] = useState(false);

  const registerForm = createRegisterForm(
    setShowSuccess,
    setShowError,
    setShowProcess
  );

  const formik = useFormik(registerForm);

  return (
    <Header
      currentLanguage={currentLanguage}
      setCurrentLanguage={setCurrentLanguage}
    >
      <AuthContainer title={t("Login.Register.Title")}>
        <Form className="mt-4" onSubmit={formik.handleSubmit}>
          <div className="form-floating form-floating-custom mb-3">
            <TextField
              placeholder={t("Login.Username")}
              formik={formik}
              valueName={"username"}
              id="input-username"
            />
            <label for="input-username">{t("Login.Username")}</label>
            <div className="form-floating-icon">
              <UilUser />
            </div>
          </div>

          <div className="form-floating form-floating-custom mb-3">
            <TextField
              placeholder={t("Login.Email")}
              formik={formik}
              valueName={"email"}
              id="input-email"
            />
            <label for="input-email">{t("Login.Email")}</label>
            <div className="form-floating-icon">
              <UilEnvelope />
            </div>
          </div>
          <div className="form-floating form-floating-custom mb-3">
            <TextField
              placeholder={t("Login.Password")}
              formik={formik}
              valueName={"password"}
              password={true}
              id="input-password"
            />
            <label for="input-password">{t("Login.Password")}</label>
            <div className="form-floating-icon">
              <UilLock />
            </div>
          </div>
          <div className="form-floating form-floating-custom mb-3">
            <TextField
              placeholder={t("Login.PasswordConfirm")}
              formik={formik}
              valueName={"passwordconfirmation"}
              password={true}
              className={""}
              id="input-passwordconfirmation"
            />
            <label for="input-passwordconfirmation">
              {t("Login.PasswordConfirm")}
            </label>
            <div className="form-floating-icon">
              <UilLock />
            </div>
          </div>
          <div className="form-floating form-floating-custom mb-3">
            <button
              className="btn btn-info w-100"
              type="submit"
              onClick={() => setShowError(false)}
            >
              {t("Login.SignUpButton")}
            </button>
          </div>
        </Form>
        <div className="d-flex justify-content-between mt-4">
          <Link
            to={"/resetpassword"}
            className="btn-link text-decoration-none mr-3"
          >
            {t("Login.ForgotLink")}
          </Link>
          <Link to={"/login"} className="btn-link text-decoration-none">
            {t("Login.Login")}
          </Link>
        </div>
        <div className="border-top pt-3 mt-3 text-center">
          {showError ? (
            <span style={{ color: "red" }}> {t("Login.Messages.Error")} </span>
          ) : (
            ""
          )}
          {showSuccess ? (
            <span style={{ color: "green" }}>
              {t("Login.Messages.Success")}{" "}
            </span>
          ) : (
            ""
          )}
          {showProcess ? t("Login.Messages.Progress") : ""}
        </div>
      </AuthContainer>
    </Header>
  );
};

export default Signup;
