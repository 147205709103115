import PostForm from "@serie3/mymediaplanner/Modules/Social/Forms/PostForm";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import NewModal from "../../shared/Modals/NewModal";
import SaveButton from "../../shared/Buttons/SaveButton";
import Post from "@serie3/mymediaplanner/Modules/Social/Entity/Post";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ComposePostTab from "./ComposPostTab";
import SchedulePostTab from "./SchedulePostTab";
import Teleprompter from "./Telepromter";
import StatsTab from "./StatsTab";
import { useEffect } from "react";

const PostFormView = ({
  showEdit,
  setShowEdit,
  editData,
  dataList,
  setDataList,
  setEditData,
  trigger,
  setTrigger,
}) => {
  const editPostForm = PostForm.createPostForm(
    dataList,
    setDataList,
    setShowEdit
  );

  const { t } = useTranslation();
  const formik = useFormik(editPostForm);

  const Save = () => {
    return (
      <>
        <SaveButton
          submitFunction={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
          }}
        />
      </>
    );
  };

  useEffect(() => {
    if (showEdit) {
      const intervalId = setInterval(() => {
        formik.handleSubmit();
      }, 10000); // Every 30 seconds

      return () => clearInterval(intervalId); // Cleanup on component unmount
    }
  }, [showEdit]);

  return (
    <>
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        title={formik.values.title}
        ExtraButton={Save}
        fullscreen={true}
        onShow={() => {
          editData
            ? formik.setValues(PostForm.setEditData(editData))
            : formik.setValues(Post);
        }}
        onExit={() => {
          let triggervalue = trigger * -1;
          setTrigger && setTrigger(triggervalue);
          formik.resetForm({ values: { Post } });
        }}
      >
        <Tabs
          defaultActiveKey="compose"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="compose" title={t("Backend.Post.Tabs.Compose")}>
            <ComposePostTab
              formik={formik}
              editData={editData}
              setEditData={setEditData}
            />
          </Tab>
          <Tab eventKey="schedule" title={t("Backend.Post.Tabs.Schedule")}>
            <SchedulePostTab post={editData} />
          </Tab>
          <Tab eventKey="prompter" title={t("Backend.Post.Tabs.Prompter")}>
            <Teleprompter text={editData ? editData.text : ''} />
          </Tab>
          {(editData && editData.stats.length > 0) && (
            <Tab eventKey="stats" title={t("Backend.Post.Tabs.Stats")}>
              <StatsTab stats={editData.stats} />
            </Tab>
          )}
        </Tabs>
      </NewModal>
    </>
  );
};

export default PostFormView;
