import HeaderNavbar from "../../../shared/HeaderNavbar";
import HeroImage from "../../../../assets/images/landingpage/heroImage.svg";

import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";

const Header = ({ currentLanguage, setCurrentLanguage, children }) => {
  const { t } = useTranslation();
  return (
    <div className="navbarContainer">
      <Container style={{ paddingBottom: "50px" }}>
        <HeaderNavbar
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
        />
        {children}
      </Container>
    </div>
  );
};

export default Header;
