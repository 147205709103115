import { Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewModal from "../../shared/Modals/NewModal";
import { useState } from "react";
import NewButtonWithIcon from "../../shared/Buttons/NewButtonWithIcon";
import crudApi from "@serie3/common/api/crudApi";
import { useFormik } from "formik";
import ScheduleForm from "@serie3/mymediaplanner/Modules/Social/Forms/ScheduleForm";
import Schedule from "@serie3/mymediaplanner/Modules/Social/Entity/Schedule";
import DateTimeField from "../../Form/DateTimeField";
import SelectField from "../../Form/SelectField";
import SaveButton from "../../shared/Buttons/SaveButton";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import TextField from "../../Form/TextField";
import Timezones from "../../Form/Timezones";
import PostCalendar from "../PostCalendar";
import { useEffect } from "react";
import ScheduleTable from "./Schedules/ScheduleTable";
import ScheduleFormView from "./Schedules/ScheduleFormView";

const SchedulePostTab = ({ post }) => {
  const { t } = useTranslation();
  const [showPublished, setShowPublished] = useState(false);
  const [showPlanningForm, setshowPlanningForm] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [editData, setEditData] = useState();
  const [schedules, setSchedules] = useState([]);
  const [draft, setDraft] = useState(post.draft);

  const scheduleForm = ScheduleForm.createScheduleForm(
    dataList,
    setDataList,
    setSchedules,
    schedules,
    post.id
  );

  const formik = useFormik(scheduleForm);

  const addFunction = () => {
    setEditData(null);
    setshowPlanningForm(true);
  };

  const setPublishedFunction = () => {
    setEditData(null);
    setShowPublished(true);
  };

  const Save = () => {
    return <SaveButton formik={formik} />;
  };

  useEffect(() => {
    const fetchPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.schedule.index,
        setSchedules,
        SocialEndpoints.posts.scheduledDataField
      );
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    const fetchSchedules = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.schedule.indexAllForPost + post.id,
        setDataList,
        SocialEndpoints.posts.scheduledDataField
      );
    };

    fetchSchedules();
  }, [post.id]);

  const setDraftFalse = async () => {
    const response = await crudApi.justGet(
      SocialEndpoints.posts.setDraft + post.id
    );
    if (response.ok) {
      setDraft(false);
      formik.setValues("draft", false);
    }
  };

  return draft === false ? (
    <>
      <ScheduleFormView
        formik={formik}
        showPlanningForm={showPlanningForm}
        setshowPlanningForm={setshowPlanningForm}
        editData={editData}
      />
      <NewModal
        title={t("Backend.Post.SetPublished")}
        show={showPublished}
        setShow={setShowPublished}
        onExit={() => {
          formik.resetForm({ values: { Schedule } });
        }}
        onShow={() => {
          editData
            ? formik.setValues(ScheduleForm.setEditData(editData))
            : formik.setValues(Schedule);
        }}
        ExtraButton={Save}
      >
        <Row className="mt-5">
          <Col>
            <DateTimeField
              formik={formik}
              label={t("Backend.Post.Schedule.PostedAt")}
              valueName={"posted_at"}
            />
          </Col>
          <Col>
            <Timezones formik={formik} />
          </Col>
          <Col>
            <TextField
              formik={formik}
              label={t("Backend.Post.PortalId")}
              valueName={"portal_post_id"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectField
              formik={formik}
              valueName={"platform"}
              label={t("Backend.Post.Schedule.Platformname")}
            >
              <option value={"linkedin"}>LinkedIn</option>
            </SelectField>
          </Col>
        </Row>
      </NewModal>
      <Row>
        <Col>
          <h3>{t("Backend.Post.Schedule.ScheduleTitle")}</h3>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <span className="me-3">
                <NewButtonWithIcon
                  handleClick={setPublishedFunction}
                  title={t("Backend.Post.Schedule.SetPublished")}
                />
              </span>
              <NewButtonWithIcon
                handleClick={addFunction}
                title={t("Backend.Post.Schedule.Plan")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ScheduleTable
                dataList={dataList}
                setDataList={setDataList}
                setEditData={setEditData}
                setshowPlanningForm={setshowPlanningForm}
                schedules={schedules}
                setSchedules={setSchedules}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <h3>{t("Backend.Post.Schedule.CalendarTitle")}</h3>
          <PostCalendar schedules={schedules} dontUseCalendarClick={true}/>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <h3>{t("Backend.Post.Schedule.MarkPostDoneFirst")}</h3>
      <Button
        type="button"
        className="btn btn-success d-flex align-items-center"
        onClick={() => {
          setDraftFalse();
        }}
      >
        {t("Backend.Post.SetReady")}
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="ms-2" // Adds a small margin to the left of the icon
        />
      </Button>
    </>
  );
};

export default SchedulePostTab;
