const Post = {
  title: "",
  text: "",
  draft: true,
  post_type: "text",
  content_type: "personal",
  content_pillar: {id: null}
};

export default Post;
