import { Col, Card } from "react-bootstrap";
import getSettings from "@serie3/common/config/settings";
import { Link } from "react-router-dom";
import DummyImage from "../../assets/images/landingpage/dummyImage.svg";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const BlogCard = ({ post = null, size = 3 }) => {
  const settings = getSettings();
  let navigate = useNavigate();
  const { t } = useTranslation();

  if (!post) {
    return null; // Early return if post is undefined or null
  }

  const imageUrl = post.image
    ? settings.blogUrl + post.image
    : "https://felixtravelblog.de/fileadmin/templates/felix/travelblog/template/images/backgroundTravelBlog.jpg";
  const slug = post.slug.replace("blogapi", "blog") || "";

  return (
    <Col md={size} className="mb-3">
      <div className="blogCard">
        <Link to={slug}>
          <Card style={{ background: "none", border: "none" }}>
            <Card.Img variant="top" src={DummyImage} />
            <Card.Body className="d-flex flex-column">
              <Card.Title
                style={{ marginTop: "40px", marginBottom: "20px" }}
                className="blogCardTitle"
              >
                {post.title}
              </Card.Title>
              <Card.Text>{post.description}</Card.Text>
              <button
                className="actionButton"
                style={{ marginTop: "40px" }}
                onClick={() => {
                  navigate(`${slug}`);
                }}
              >
                {t("Blog.More")}
              </button>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </Col>
  );
};

export default BlogCard;
