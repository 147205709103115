import { Routes, Route } from "react-router-dom";
import Signup from "./Login/Signup";
import VerifyEmail from "./Login/VerifyEmail";
import Forgot from "./Login/Forgot";
import RequestValidationLink from "./Login/RequestValidationLink";
import Login from "./Login/Login";
import NotFoundPage from "../shared/404";
import "react-multi-carousel/lib/styles.css";
import Landingpage from "./Landingpage";
import Pricing from "./Login/Pricing";

const FrontendRouter = ({ setToken, currentLanguage, setCurrentLanguage  }) => {
  return (
    <>
      <Routes>
        <Route path="/signup" element={<Signup currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/>} />
        <Route path="/verifyemail" element={<VerifyEmail currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/>} />
        <Route path="/pricing" element={<Pricing currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/>} />
        <Route path="/resetpassword" element={<Forgot currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/>} />
        <Route path="/login" element={<Login setToken={setToken} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/>} />
        <Route
          path="/requestemailvalidation"
          element={<RequestValidationLink />}
        />
        <Route path="/" element={<Landingpage currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/>} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default FrontendRouter;
