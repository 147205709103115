import React from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    className="btn btn-success dropdown-toggle dropdown-toggle-split"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{
      borderBottomRightRadius: "50px",
      borderTopRightRadius: "50px",
      border: "1px solid var(--Color-skyblue, #5FD0F3)",
      background: "var(--Color-skyblue, #5FD0F3)",
      boxShadow: "none"
    }}
  >
    {children}
  </button>
));

export default CustomToggle;
