import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import endpoints from "@serie3/common/config/endpoints";
import { useTranslation } from "react-i18next";
import AuthContainer from "./AuthContainer";
import Header from "../Landingpage/Subcomponents/Header";

const VerifyEmail = ({ currentLanguage, setCurrentLanguage }) => {
  const [registerStatus, setRegisterStatus] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const verifyData = async () => {
      const endpoint = endpoints.register.verify + window.location.search;
      const response = await crudApi.justGet(endpoint);
      setRegisterStatus(response.status);
    };
    verifyData();
  }, []);

  return (
    <Header
      currentLanguage={currentLanguage}
      setCurrentLanguage={setCurrentLanguage}
    >
      <AuthContainer>
        <Row className="justify-content-md-center">
          <Col md={12} style={{ textAlign: "center" }}>
            <h3>
              {registerStatus ? (
                registerStatus === 200 ? (
                  <span style={{ color: "green" }}>
                    {t("Login.Register.Success")}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    {t("Login.Register.Error")}
                  </span>
                )
              ) : (
                t("Login.Register.Working")
              )}
            </h3>
          </Col>
        </Row>
      </AuthContainer>
    </Header>
  );
};

export default VerifyEmail;
