import NewModal from "../shared/Modals/NewModal";
import LoginForm from "../frontend/Login/LoginForm";

const LoginModal = ({ show, setShow, token, setToken }) => {
  return <NewModal show={show} setShow={setShow}>
    <LoginForm setShow={setShow} setToken={setToken} token={token}/>
  </NewModal>;
};

export default LoginModal;
