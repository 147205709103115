import { Container, Row, Col } from "react-bootstrap";

const AuthContainer = ({ children, title, description }) => {
  return (
    <Row className="justify-content-center my-auto">
      <Col md={8} lg={6} xl={4}>
        <h5>{title}</h5>
        <p>{description}</p>
        {children}
      </Col>
    </Row>
  );
};

export default AuthContainer;
