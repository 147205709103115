import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper React components
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Slider from "./Slider";
import CompleteDropdown from "../../../../shared/Dropdown/CompleteDropdown";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import listHandler from "@serie3/common/Domain/listHandler";
import AddButton from "../../../../shared/Buttons/AddButton";
import { Col, Row } from "react-bootstrap";
import Interactions from "../../../../shared/Interactions";

const ContentPillarTimeline = ({
  pillar,
  pillars,
  setPillars,
  index,
  editAction,
  setEditPostList,
  setShowPostForm,
  setEditPost,
  trigger,
}) => {
  const { t } = useTranslation();

  const [posts, setPosts] = useState([]);
  const [statsSet, setStatsSet] = useState(false);
  const [stats, setStats] = useState({
    views: 0,
    likes: 0,
    comments: 0,
    averageViews: 0,
    averageLikes: 0,
    averageComments: 0,
  });

  const [averageViews, setAverageViews] = useState(null);
  const [averageLikes, setAverageLikes] = useState(null);
  const [averageComments, setAverageComments] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.contentPillars.showposts + pillar.id,
        setPosts,
        "posts"
      );
    };
    fetchPosts();
  }, [trigger]);

  useEffect(() => {
    let totalViews = 0;
    let totalLikes = 0;
    let totalComments = 0;
    let count = 0;

    posts.forEach((post) => {
      if (post.stats && post.stats.length > 0) {
        let views = Number(post.stats?.at(-1)?.views);
        let likes = Number(post.stats?.at(-1)?.likes);
        let comments = Number(post.stats?.at(-1)?.comments);

        // Add only if it's a valid number
        if (!isNaN(views)) totalViews += views;
        if (!isNaN(likes)) totalLikes += likes;
        if (!isNaN(comments)) totalComments += comments;

        count++; // Count only valid posts with stats
      }
    });

    // Avoid division by zero
    const averageViews = count > 0 ? Math.round(totalViews / count) : 0;
    const averageLikes = count > 0 ? Math.round(totalLikes / count) : 0;
    const averageComments = count > 0 ? Math.round(totalComments / count) : 0;

    setAverageComments(averageComments);
    setAverageLikes(averageLikes);
    setAverageViews(averageViews);

    setStats({
      views: totalViews,
      likes: totalLikes,
      comments: totalComments,
    });

    setStatsSet(true);
  }, [posts]); // Dependency on posts means this runs when posts change

  const deleteAction = () => {
    const deleteUrl = SocialEndpoints.posts.contentPillars.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, pillar.id),
      setPillars,
      pillars
    );
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="hori-timeline">
              <div className="swiper-container slider">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <h5 className="card-title">{pillar.pillar}</h5>
                    <small>{pillar.pillar}</small>
                  </div>
                  <div className="flex-shrink-0 swiper-arrow d-flex gap-2 justify-content-end arrow-sm">
                    {/* Custom navigation buttons */}
                    <div
                      className={`swiper-button-prev prev-${pillar.id} prev position-relative rounded-start`}
                    ></div>
                    <div
                      className={`swiper-button-next next-${pillar.id} next position-relative rounded-end`}
                    ></div>
                    <CompleteDropdown
                      editAction={() => editAction(pillar)}
                      deleteAction={
                        posts.length === 0 ? () => deleteAction(pillar) : null
                      }
                    />
                  </div>
                </div>
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={50}
                  slidesPerView={3}
                  navigation={{
                    prevEl: `.prev-${pillar.id}`,
                    nextEl: `.next-${pillar.id}`,
                  }}
                >
                  <SwiperSlide>
                    <div class="event-list">
                      <div
                        class="event-list-item"
                        style={{ backgroundColor: "#f5f6f8" }}
                      >
                        <p class="font-size-13 text-primary">&nbsp;</p>
                        <p class="text-muted"></p>
                        <Row>
                          <Col>
                            <span className="me-3">
                              {t("Backend.Post.ContentPillars.Total")}
                            </span>
                            <Interactions
                              views={stats.views}
                              likes={stats.likes}
                              comments={stats.comments}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-5">
                            <span className="me-3">
                              {t("Backend.Post.ContentPillars.Average")}
                            </span>
                            <Interactions
                              views={averageViews}
                              likes={averageLikes}
                              comments={averageComments}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </SwiperSlide>
                  {averageViews && posts.length > 0 ? (
                    posts.map((post, index) => (
                      <SwiperSlide
                        key={post.id || index}
                        style={{ transform: "none" }}
                      >
                        <Slider
                          post={post}
                          posts={posts}
                          setPosts={setPosts}
                          index={index}
                          setEditPost={setEditPost}
                          setShowEdit={setShowPostForm}
                          averageLikes={averageLikes}
                          averageComments={averageComments}
                          averageViews={averageViews}
                        />
                      </SwiperSlide>
                    ))
                  ) : (
                    <p>Loading posts...</p> // Optional: Add a fallback UI
                  )}
                  <SwiperSlide>
                    <div class="event-list">
                      <div
                        class="event-list-item"
                        style={{ backgroundColor: "#f5f6f8" }}
                      >
                        <p class="font-size-13 text-primary">&nbsp;</p>
                        <h5 class="font-size-14 mb-1">
                          {t("Backend.Post.New")}
                        </h5>
                        <p class="text-muted"></p>
                        <div>
                          <p class="text-muted text-truncate pb-5">
                            <AddButton
                              addFunction={async () => {
                                let response = await crudApi.createNew(
                                  SocialEndpoints.posts.new,
                                  {}
                                );
                                if (response.ok) {
                                  listHandler.newToList(
                                    response,
                                    posts,
                                    setPosts,
                                    null
                                  );
                                  setEditPostList(posts);
                                  let newPost = { ...response.data };
                                  newPost.content_pillar = { id: pillar.id };
                                  setEditPost(newPost);
                                  setShowPostForm(true);
                                }
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentPillarTimeline;
