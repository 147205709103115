import { Form } from "react-bootstrap";

const SelectField = ({
  formik,
  formClass = null,
  label,
  valueName,
  value,
  style = null,
  children,
  onChange
}) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId={valueName}
      style={style}
    >
      <Form.Label>{label}:</Form.Label>
      <Form.Select
        className={"custom-select form-control-border"}
        name={valueName}
        value={value}
        onChange={(e) => {onChange ? onChange(e) : formik.setFieldValue(valueName, e.target.value)}}
        style={{ width: "100%" }}
        isInvalid={!!formik.errors[valueName]}
      >
        {children}
      </Form.Select>
    </Form.Group>
  );
};

export default SelectField;
