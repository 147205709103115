import TextField from "../../../Form/TextField";
import {  Row, Col, Button } from "react-bootstrap";
import { useFormik } from "formik";
import IdeaForm from "@serie3/mymediaplanner/Modules/Social/Forms/IdeaForm";
import { useTranslation } from "react-i18next";

const IdeasForm = ({
  formikCreateideaAssistantForm,
  post,
  showSpinner,
  setShowSpinner,
  ideas = [],
  setIdeas,
}) => {
  const { t } = useTranslation();

  const ideaForm = IdeaForm.createIdeaForm();
  const formikIdeaForm = useFormik(ideaForm);

  return (
    <Row>
      <Col
        dangerouslySetInnerHTML={{
          __html: post && post.text && post.text.replace(/\n/g, "<br />"),
        }}
      />
      {ideas.length > 0 ? (
        <Col>
          {ideas.map((idea, index) => {
            return (
              <Row className="mb-5">
                <Col>
                  <h5>{idea.title} </h5>
                  <Row>
                    <Col>
                      <span className="badge me-3 font-size-12 badge bg-link rounded-pill badge-info-subtle">
                        {idea.topic}
                      </span>
                      <span className="badge me-3 font-size-12 badge bg-link rounded-pill badge-info-subtle">
                        {idea.tone}
                      </span>
                      <span className="badge me-3 font-size-12 badge bg-link rounded-pill badge-info-subtle">
                        {idea.contentType}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      dangerouslySetInnerHTML={{
                        __html: idea.text.replace(/\n/g, "<br />"),
                      }}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        onClick={() => {
                          formikIdeaForm.resetForm();
                          formikIdeaForm.setFieldValue("title", idea.title);
                          formikIdeaForm.setFieldValue("text", idea.text);
                          formikIdeaForm.setFieldValue("tone", idea.tone);
                          formikIdeaForm.setFieldValue("topic", idea.topic);
                          formikIdeaForm.setFieldValue(
                            "content_type",
                            idea.contentType
                          );
                          formikIdeaForm.setFieldValue(
                            "platform",
                            idea.platform
                          );
                          formikIdeaForm.submitForm();
                        }}
                      >
                        {t("Backend.Post.Ideas.SaveIdea")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Col>
      ) : (
        <Col>
          {showSpinner ? (
            <>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div> {t("Backend.Post.Variants.Thinking")}</div>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <TextField
                    formik={formikCreateideaAssistantForm}
                    label={t("Backend.Post.Ideas.Topic")}
                    valueName={"topic"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <small>{t("Backend.Post.Ideas.Example")}</small>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <button
                    className="btn btn-primary me-2"
                    onClick={async () => {
                      setShowSpinner(true);
                      setIdeas([]);
                      formikCreateideaAssistantForm.setFieldValue(
                        "title",
                        post ? post.title : ""
                      );
                      formikCreateideaAssistantForm.setFieldValue(
                        "post_type",
                        post ? post.post_type : ""
                      );
                      formikCreateideaAssistantForm.setFieldValue(
                        "platform",
                        "linkedin"
                      );
                      formikCreateideaAssistantForm.setFieldValue(
                        "text",
                        post ? post.text : ""
                      );
                      formikCreateideaAssistantForm.submitForm();
                    }}
                  >
                    {t("Backend.Post.Ideas.Generate")}
                  </button>
                </Col>
              </Row>
            </>
          )}
        </Col>
      )}
    </Row>
  );
};

export default IdeasForm;
