import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import PollForm from "@serie3/mymediaplanner/Modules/Social/Forms/PollForm";
import { useFormik } from "formik";
import TextField from "../../../Form/TextField";
import { useEffect } from "react";
import SelectField from "../../../Form/SelectField";

const PollFormView = ({ poll, setPoll }) => {
  const { t } = useTranslation();

  const editPollForm = PollForm.createPollForm(setPoll);
  const formik = useFormik(editPollForm);

  useEffect(() => {
    formik.setValues(PollForm.setEditData(poll));
  }, [poll.id]);

  return (
    <>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Post.Poll.Title")}
            valueName="title"
            showErrors={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectField
            formik={formik}
            label={t("Backend.Post.Poll.Duration")}
            valueName="duration"
          >
            <option value="ONE_DAY">
              {t("Backend.Post.Poll.Runtime.One")}
            </option>
            <option value="THREE_DAYS">
              {t("Backend.Post.Poll.Runtime.Three")}
            </option>
            <option value="SEVEN_DAYS">
              {t("Backend.Post.Poll.Runtime.Seven")}
            </option>
            <option value="FOURTEEN_DAYS">
              {t("Backend.Post.Poll.Runtime.Fourteen")}
            </option>
          </SelectField>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Post.Poll.Option1")}
            valueName="option1"
            showErrors={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Post.Poll.Option2")}
            valueName="option2"
            showErrors={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Post.Poll.Option3")}
            valueName="option3"
            showErrors={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Post.Poll.Option4")}
            valueName="option4"
            showErrors={true}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            onClick={() => {
              formik.submitForm();
            }}
          >
            {t("Backend.Post.Poll.SavePoll")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PollFormView;
