import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import Brand from "../../../shared/Brand";

const Footer = ({ props }) => {
  const { t } = useTranslation();

  return (
    <div className="footerBackground">
      <Container>
        <footer>
          <Row>
            <Col>
              <Row className="mb-5">
                <Col>
                  <Brand useBlack={false} />
                </Col>
              </Row>
              <Row>
                <Col>
                {t("Footer.Slogan")}
                </Col>
              </Row>
            </Col>
            <Col>
              <h3>Features</h3>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">Content Recycling</li>
                <li className="nav-item mb-2">Posts planning</li>
                <li className="nav-item mb-2">Content Pillars</li>
                <li className="nav-item mb-2">Asset management</li>
              </ul>
            </Col>
            <Col>
              <h3>Company</h3>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a
                    href="https://www.linkedin.com/in/felixdziekan/"
                    target="_blank"
                    style={{ color: "#74788d" }}
                  >
                    Founder: Felix Dziekan
                  </a>
                </li>
              </ul>
            </Col>
            <Col>
              <h3>Legal</h3>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a href="/blog/impressum" style={{ color: "#74788d" }}> {t("Footer.Imprintlink")}</a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/blog/datenschutz" style={{ color: "#74788d" }}> {t("Footer.Privacylink")}</a>
                </li>
              </ul>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col style={{ textAlign: "center" }}>
              Copyright © 2025 Mymediaplanner. All Rights Reserved.
            </Col>
          </Row>
        </footer>
      </Container>
    </div>
  );
};

export default Footer;
