import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import listHandler from "@serie3/common/Domain/listHandler";
import {
    faTrash,
    faPen,
    faLink,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import crudApi from "@serie3/common/api/crudApi";
  import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";


const ScheduleTable = ({dataList, setDataList, setEditData, setshowPlanningForm, schedules, setSchedules}) => {
  const { t } = useTranslation();


  const deleteSchedule = (index, scheduleId) => {
    listHandler
      .deleteFromList(
        index,
        crudApi.del(SocialEndpoints.posts.schedule.delete + scheduleId),
        setDataList,
        dataList
      )
      .then(() => {
        // Update schedules here after deletion is completed
        const updatedSchedules = schedules.filter(
          (schedule) => schedule.id !== scheduleId
        );
        setSchedules(updatedSchedules);
      })
      .catch((error) => {
        console.error("Error deleting schedule:", error);
      });
  };

  return (
    
    <Table striped bordered hover size="sm">
    <thead>
      <tr>
        <th>{t("Backend.Post.Schedule.Date")}</th>
        <th>{t("Translation.Timezone")}</th>
        <th>{t("Backend.Post.Schedule.Platform")}</th>
        <th>{t("Backend.Post.Schedule.Status")}</th>
        <th colSpan={2}></th>
      </tr>
    </thead>
    <tbody>
      {dataList &&
        dataList.map((schedule, index) => (
          <tr>
            <td>{formatDate(schedule.local_user_time)}</td>
            <td>{schedule.timezone}</td>
            <td>{schedule.platform}</td>
            <td>{schedule.post_status}</td>
            {schedule.portal_post_id && (
              <td style={{ color: "#51d28c" }}>
                <a
                  href={`https://www.linkedin.com/feed/update/${schedule.portal_post_id}`}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLink}
                    className="ms-2 me-2"
                  />
                </a>
              </td>
            )}
            <td style={{ color: "#51d28c" }}>
              <FontAwesomeIcon
                icon={faPen}
                className="ms-2 me-2"
                onClick={() => {
                  setEditData(schedule);
                  setshowPlanningForm(true);
                }}
              />
            </td>
            <td style={{ color: "#f34e4e" }}>
              <FontAwesomeIcon
                icon={faTrash}
                className="ms-2 me-2"
                onClick={() => {
                  deleteSchedule(index, schedule.id);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  </Table>
    
  )
};

export default ScheduleTable;
