import Poll from "../Entity/Poll";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";

const createPollForm = (
  setDataFunction,
) => {
  const validationSchema = Yup.object({
    title: Yup.string().max(140, 'Max 140 characters allowed').required(),
    option1: Yup.string().max(30, 'Max 30 characters allowed').required(),
    option2: Yup.string().max(30, 'Max 30 characters allowed').required(),
    option3: Yup.string().max(30, 'Max 30 characters allowed').nullable(true).notRequired(),
    option4: Yup.string().max(30, 'Max 30 characters allowed').nullable(true).notRequired()

  });

  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(
      SocialEndpoints.posts.poll.edit,
      payload
    );
    if(response.ok){
      setDataFunction(response.data);
    }
  };

  return {
    initialValues: Poll,
    validationSchema:validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Poll = {
    id: editData.id,
    option1: editData.option1,
    option2: editData.option2,
    option3: editData.option3,
    option4: editData.option4,
    title: editData.title,
    duration: editData.duration    
  };

  return Poll;
};

export default { createPollForm, setEditData };
