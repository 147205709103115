import { useTranslation } from "react-i18next";
import Header from "../Landingpage/Subcomponents/Header";
import LoginForm from "./LoginForm";

const Login = ({
  setShow,
  setToken,
  token = null,
  currentLanguage,
  setCurrentLanguage,
}) => {
  const { t } = useTranslation();

  return (
    <Header
      currentLanguage={currentLanguage}
      setCurrentLanguage={setCurrentLanguage}
    >
      <LoginForm
        setShow={setShow}
        setToken={setToken}
        token={token}
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
    </Header>
  );
};

export default Login;
