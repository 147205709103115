import { Form } from "react-bootstrap";

const TextField = ({
  formik = null,
  formClass = null,
  label = null,
  placeholder = null,
  valueName = null,
  style = null,
  value = null,
  password = false,
  namedReference = null,
  showErrors = false,
}) => {
  return (
    <>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        placeholder={placeholder}
        type={password ? "password" : "text"}
        name={valueName}
        value={formik && (value ? value : formik.values[valueName])}
        isInvalid={formik && !!formik.errors[valueName]}
        onChange={formik && formik.handleChange}
        style={{ ...style }}
        ref={namedReference}
        className={formClass ? formClass : ""}
      />
      {showErrors && formik && formik.touched[valueName] && formik.errors[valueName] ? (
        <div className="invalid-feedback d-block mb-3">
          {formik.errors[valueName]}
        </div>
      ) : null}
    </>
  );
};

export default TextField;
