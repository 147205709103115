import PostAssistant from "../Entity/PostAssistant";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";

const createPostAssistantForm = (postId, handleResponse, setShowSpinner) => {
  const validationSchema = Yup.object({
    Tipp: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    setShowSpinner(true);
    let payload = JSON.stringify(values, null, 2);

    let response;
    if (postId) {
      response = await CrudApi.createNew(
        SocialEndpoints.posts.ai.creatAassistedPost + postId,
        payload
      );
    } else {
      response = await CrudApi.createNew(
        SocialEndpoints.posts.ai.createNewPost,
        payload
      );
    }

    if (response.ok) {
      handleResponse(response);
    }
    setShowSpinner(false);
  };

  return {
    initialValues: PostAssistant,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

export default { createPostAssistantForm };
