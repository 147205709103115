import { useTranslation } from "react-i18next";
import SelectField from "./SelectField";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import { useState, useEffect } from "react";
import crudApi from "@serie3/common/api/crudApi";

const ContentPillarSelect = ({ formik }) => {
  const { t } = useTranslation();
  const [pillars, setPillars] = useState([]);
  useEffect(() => {
    const fetchPillars = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.contentPillars.index,
        setPillars,
        "contentpillars"
      );
    };
    fetchPillars();
  }, []);

  return (
    <SelectField
      formik={formik}
      label={t("Backend.Post.ContentPillars.Title")}
      valueName="content_pillar"
      style={{ marginBottom: "10px" }}
      value={
        formik.values.content_pillar ? formik.values.content_pillar.id : null
      }
      onChange={(event) => {
        const selectedId = event.target.value;
        formik.setFieldValue("content_pillar", { id: selectedId });
      }}
    >
      <option></option>
      {pillars.map((pillar, index) => (
        <option key={pillar.id} value={pillar.id}>
          {pillar.pillar}
        </option>
      ))}
    </SelectField>
  );
};

export default ContentPillarSelect;
