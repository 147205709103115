import { useState, useEffect } from "react";
import CompleteDropdown from "../../shared/Dropdown/CompleteDropdown";
import { Dropdown, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCircleCheck,
  faBan,
  faRecycle,
  faPlayCircle,
  faStopCircle,
  faEye,
  faThumbsUp,
  faComment,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import ImagesPreview from "./Previews/ImagesPreview";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import { formatDate } from "@serie3/common/Shared/timeingStuff";

const Timelineitem = ({
  post,
  editAction,
  deleteAction,
  publish,
  setDraftAction,
  creatVariantsAction,
  markInProgress,
  createIdeasAction,
  schedule = null,
}) => {
  const [statusBarColor, setStatusBarColor] = useState("#038edc");
  const [postFiles, setPostFiles] = useState([]);
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    const fetchSchedules = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.schedule.indexAllForPost + post.id,
        setSchedules,
        SocialEndpoints.posts.scheduledDataField
      );
    };

    fetchSchedules();
  }, [post.id]);

  const timelineContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px", // Adjust the gap between timeline items as needed
  };

  const timelineItemStyle = {
    display: "flex",
    justifyContent: "space-between", // Ensure content is spaced correctly
    alignItems: "center",
  };

  const timelineDateStyle = {
    textAlign: "left",
    paddingRight: "10px", // Adjust the padding as needed
  };

  const timelineContentStyle = {
    textAlign: "right", // Ensure text is aligned to the left within the content area
    flex: 1,
  };

  const image = {
    borderRadius: "4px",
    height: "40px",
    width: "auto",
  };

  const { t } = useTranslation();

  const Tags = ({ post }) => {
    return (
      <>
        {(() => {
          let tags = [];

          if (post.draft) {
            // Highest priority: Draft status
            tags.push(
              <span
                key="draft"
                className="badge-primary-subtle font-size-12 badge bg-link rounded-pill me-3"
              >
                {t("Backend.Post.State.Draft")}
              </span>
            );
          } else {
            tags.push(
              <span
                key="ready"
                className="badge-warning-subtle font-size-12 badge bg-link rounded-pill me-3"
              >
                {t("Backend.Post.State.Ready")}
              </span>
            );
          }

          if (post.schedules && post.schedules.length > 0) {
            // Track already added statuses to avoid duplicates
            const addedStatuses = new Set();

            for (const schedule of post.schedules) {
              const status = schedule.post_status || "scheduled";

              // Skip if the status has already been added
              if (!addedStatuses.has(status)) {
                const capitalizedStatus =
                  status.charAt(0).toUpperCase() + status.slice(1);

                const badgeClass =
                  status === "published"
                    ? "badge-success-subtle"
                    : status === "scheduled"
                    ? "badge-warning-subtle"
                    : status === "ready"
                    ? "badge-light-subtle"
                    : status === "error"
                    ? "badge-danger-subtle"
                    : "badge-secondary-subtle";

                status === "published" && setStatusBarColor("#51d28c");

                tags.push(
                  <span
                    key={schedule.id}
                    className={`badge me-3 font-size-12 badge bg-link rounded-pill ${badgeClass}`}
                  >
                    {t(`Backend.Post.State.${capitalizedStatus}`)}
                  </span>
                );

                addedStatuses.add(status);
              }
            }
          }

          return tags.length > 0 ? tags : null;
        })()}
      </>
    );
  };

  let postType = post.post_type
    ? "Backend.Post.PostType." + post.post_type
    : "Backend.Post.PostType.Text";
  let contentType = post.content_type
    ? "Backend.Post.ContentType." + post.content_type
    : "Backend.Post.ContentType.Personal";

  return (
    <div className="timeline-item">
      <div className="timeline-block">
        <div
          className="timeline-box card"
          style={{ borderLeft: "2px solid " + statusBarColor }}
        >
          <div className="card-header">
            <h5>
            {schedule &&
                schedule.post_status &&
                schedule.post_status === "error" && (
                  <span
                    key={schedule.id}
                    className={`badge me-3 font-size-14 badge bg-link rounded-pill badge-danger-subtle`}
                  >
                    {t(`Backend.Post.State.Error`)}
                  </span>
                )}
              {post.title}
              <span className="pb-3">
                <CompleteDropdown
                  deleteAction={schedule ? null : deleteAction}
                  editAction={editAction}
                >
                  {!post.draft ? (
                    <Dropdown.Item
                      onClick={() => {
                        setDraftAction();
                      }}
                      className="text-danger"
                    >
                      <FontAwesomeIcon icon={faBan} className="fs-6 me-2" />
                      {t("Backend.Post.SetDraft")}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() => {
                        setDraftAction();
                      }}
                      className="text-success"
                    >
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="fs-6 me-2"
                      />
                      {t("Backend.Post.SetReady")}
                    </Dropdown.Item>
                  )}
                  <div className="dropdown-divider" />
                  {!post.in_progress ? (
                    <Dropdown.Item
                      onClick={() => {
                        markInProgress();
                      }}
                      className="text-success"
                    >
                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        className="fs-6 me-2"
                      />
                      {t("Backend.Post.SetInProgress")}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() => {
                        markInProgress();
                      }}
                      className="text-danger"
                    >
                      <FontAwesomeIcon
                        icon={faStopCircle}
                        className="fs-6 me-2"
                      />
                      {t("Backend.Post.SetNotInProgress")}
                    </Dropdown.Item>
                  )}
                  {!post.draft && (
                    <>
                      <div className="dropdown-divider" />
                      <Dropdown.Item
                        onClick={() => {
                          publish(post.id);
                        }}
                        className="text"
                      >
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className="fs-6 me-2"
                        />
                        {t("Backend.Post.Publish")}
                      </Dropdown.Item>
                    </>
                  )}
                  <div className="dropdown-divider" />
                  <Dropdown.Item
                    onClick={() => {
                      creatVariantsAction();
                    }}
                  >
                    <FontAwesomeIcon icon={faRecycle} className="fs-6 me-2" />
                    {t("Backend.Post.Variants.Create")}
                  </Dropdown.Item>
                  <div className="dropdown-divider" />
                  <Dropdown.Item
                    onClick={() => {
                      createIdeasAction();
                    }}
                  >
                    <FontAwesomeIcon icon={faLightbulb} className="fs-6 me-2" />
                    {t("Backend.Post.Variants.Ideas")}
                  </Dropdown.Item>
                </CompleteDropdown>
              </span>
            </h5>
            <Row>
              <Col>
                <small>
                  {schedule &&
                    ((schedule.post_status &&
                      schedule.post_status === "published") ||
                    schedule.post_status === "error" ? (
                      <>
                        {t("Backend.Post.State.PublishedAt")}{" "}
                        {formatDate(schedule.posted_at)} {schedule.timezone}
                      </>
                    ) : (
                      <>
                        {t("Backend.Post.State.ScheduledFor")}{" "}
                        {formatDate(schedule.scheduled_for)} {schedule.timezone}
                      </>
                    ))}
                </small>
              </Col>
              <Col>
                {post &&
                  post.stats &&
                  post.stats.length > 0 &&
                  (() => {
                    // Logging statement
                    return (
                      <span style={{ fontSize: "smaller" }}>
                        <FontAwesomeIcon icon={faEye} /> {post.stats?.at(-1).views}{" "}
                        <FontAwesomeIcon icon={faThumbsUp} className="ms-3" />{" "}
                        {post.stats?.at(-1).likes}{" "}
                        <FontAwesomeIcon icon={faComment} className="ms-3" />{" "}
                        {post.stats?.at(-1).comments}
                      </span>
                    ); // Return the actual value to be rendered
                  })()}
              </Col>
            </Row>
          </div>
          <div className="card-body">
            <Row>
              <Col md={8}>
                <div style={timelineContainerStyle}>
                  <div style={timelineItemStyle}>
                    <div style={timelineContentStyle}></div>
                  </div>
                </div>
                <div className="text-muted">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html:
                        post.text &&
                        (post.text.length > 400
                          ? post.text.slice(0, 400).replace(/\n/g, "<br />") +
                            "..."
                          : post.text.replace(/\n/g, "<br />")),
                    }}
                  />
                </div>
                <ImagesPreview postFiles={postFiles} />
              </Col>
            </Row>
          </div>
          <div className="card-footer">
            <span
              className={`badge me-3 font-size-12 badge bg-link rounded-pill badge-info-subtle`}
            >
              {t(contentType)}
            </span>
            <span
              className={`badge me-3 font-size-12 badge bg-link rounded-pill badge-success-subtle`}
            >
              {t(postType)}
            </span>
            <Tags post={post} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timelineitem;
