import { Row, Col, CardBody, Card } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import listHandler from "@serie3/common/Domain/listHandler";
import PostFormView from "./components/PostFormView";
import AddButton from "../shared/Buttons/AddButton";
import ToastMessages from "../../ToastMessages";
import { useTranslation } from "react-i18next";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LoadingModal from "../shared/Modals/LoadingModal";
import VariantsModal from "./components/VariantsModal";
import TimnelineTab from "./components/TimnelineTab";
import NewButtonWithIcon from "../shared/Buttons/NewButtonWithIcon";
import PostAssistant from "./components/PostAssistant";

const Timeline = () => {
  const [posts, setPosts] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [planned, setPlanned] = useState([]);
  const [unscheduled, setUnscheduled] = useState([]);
  const [published, setPublished] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editPost, setEditPost] = useState();
  const [trigger, setTrigger] = useState(-1);
  const [showVariants, setShowVariants] = useState(false);
  const [showPosting, setShowPosting] = useState(false);
  const [showGenerating, setShowGenerating] = useState(false);
  const [showPostAssistant, setShowPostAssistant] = useState(false);
  const [activeKey, setActiveKey] = useState("content");

  const { t } = useTranslation();

  const sortPosts = (posts) => {
    const drafts = [];
    const inProgress = [];
    const unscheduled = [];

    posts.forEach((post) => {
      if (
        post.in_progress === true &&
        (!Array.isArray(post.schedules) || post.schedules.length === 0)
      ) {
        // Add to inProgress only if it's in progress, has no schedules, and is not ready
        inProgress.push(post);
      } else if (post.draft === true) {
        drafts.push(post); // Add to drafts if it's a draft and not in progress
      } else if (Array.isArray(post.schedules) && post.schedules.length === 0) {
        unscheduled.push(post); // Add to unscheduled if it has no schedules and isn't a draft/in progress
      } else if (Array.isArray(post.schedules) && post.schedules.length > 0) {
        let isPublished = false;
        let isPlanned = false;

        post.schedules.forEach((schedule) => {
          if (schedule.post_status === "published") {
            isPublished = true;
          } else {
            isPlanned = true;
          }
        });
      }
    });

    setDrafts(drafts);
    setUnscheduled(unscheduled);
    setInProgress(inProgress);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.index,
        sortPosts,
        SocialEndpoints.posts.dataField
      );
    };

    const fetchPlannedPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.plannedOnly,
        setPlanned,
        SocialEndpoints.posts.scheduledDataField
      );
    };

    const fetchPulishedPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.postedOnly,
        setPublished,
        SocialEndpoints.posts.scheduledDataField
      );
    };

    fetchPulishedPosts();
    fetchPlannedPosts();
    fetchPosts();
  }, [trigger]);

  const editAction = (post) => {
    setEditPost(post);
    setShowEdit(true);
  };

  const postErrorFunction = () => {
    let message = t("Backend.Post.ErrorPublishing");
    ToastMessages.Error(message);
  };

  const AddBtn = () => {
    return (
      <>
        <NewButtonWithIcon
          show={showPostAssistant}
          setShow={setShowPostAssistant}
          handleClick={() => setShowPostAssistant(1)}
          title={t("Backend.Post.Assistant.Startbutton")}
        />
        <AddButton
          addFunction={async () => {
            let response = await crudApi.createNew(
              SocialEndpoints.posts.new,
              {}
            );
            if (response.ok) {
              listHandler.newToList(response, posts, setPosts, null, true);
              setEditPost(response.data);
              setShowEdit(true);
            }
          }}
        />
      </>
    );
  };

  const deleteAction = (updateListFunction, dataList, index, postId) => {
    const deleteUrl = SocialEndpoints.posts.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, postId),
      updateListFunction,
      dataList
    );
  };

  const publish = async (postId) => {
    setShowPosting(true);
    const response = await crudApi.justGet(
      SocialEndpoints.posts.publish.linkedin + postId
    );
    let data = JSON.stringify(response.data);
    listHandler.updateList(
      response,
      data,
      posts,
      setPosts,
      postErrorFunction,
      true
    );
    if (response.ok) {
      let message = t("Backend.Post.SuccessfulPublished");
      ToastMessages.SuccessfulSave(message);
    } else {
      let message = t("Backend.Post.ErrorPublishing");
      ToastMessages.SuccessfulSave(message);
    }
    setShowPosting(false);
  };

  const setDraft = async (post, list) => {
    const response = await crudApi.justGet(
      SocialEndpoints.posts.setDraft + post.id
    );
    let data = JSON.stringify(response.data);
    listHandler.updateList(
      response,
      data,
      posts,
      setPosts,
      postErrorFunction,
      true
    );
    if (response.ok) {
      const editetPost = JSON.parse(data);
      //reomove from draft list or inProgress list and move to unscheduled
      listHandler.deleteWithoutCall(post, list);

      if (editetPost.draft && editetPost.inProgress) {
        inProgress.unshift(editetPost);
      }

      if (editetPost.draft && !editetPost.inProgress) {
        drafts.unshift(editetPost);
      }

      if (!editetPost.draft) {
        unscheduled.unshift(editetPost);
      }

      let message = t("Backend.Post.SuccessfulPublished");
      ToastMessages.SuccessfulSave(message);
    }
  };

  const markInProgress = async (post, list) => {
    const response = await crudApi.justGet(
      SocialEndpoints.posts.markInProgress + post.id
    );
    let data = JSON.stringify(response.data);
    listHandler.updateList(
      response,
      data,
      posts,
      setPosts,
      postErrorFunction,
      true
    );

    if (response.ok) {
      listHandler.deleteWithoutCall(post, list);
      const editetPost = JSON.parse(data);
      if (editetPost.draft) {
        if (editetPost.in_progress) {
          inProgress.unshift(editetPost);
        } else {
          drafts.unshift(editetPost);
        }
      }
    }
  };

  return (
    <>
      <PostAssistant
        show={showPostAssistant}
        setShow={setShowPostAssistant}
        setShowEdit={setShowEdit}
        posts={drafts}
        setPosts={setDrafts}
        setEditPost={setEditPost}
        showSpinner={showGenerating}
        setShowSpinner={setShowGenerating}
      />
      <VariantsModal
        show={showVariants}
        setShow={setShowVariants}
        post={editPost}
        dataList={drafts}
        setDataList={setDrafts}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
      />
      <LoadingModal
        showSpinner={showPosting}
        setShowSpinner={setShowPosting}
        text={t("Backend.Post.Publishing")}
      />
      <LoadingModal
        showSpinner={showGenerating}
        setShowSpinner={setShowGenerating}
        text={t("Backend.Post.Assistant.GeneratingPost")}
      />
      {editPost && (
        <PostFormView
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          dataList={drafts}
          setDataList={setDrafts}
          editData={editPost}
          setEditData={setEditPost}
          setTrigger={setTrigger}
          trigger={trigger}
        />
      )}
      <h4 className="mb-sm-0">Content</h4>

      <Card className="mt-3">
        <CardBody>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <AddBtn />
              </div>
            </Col>
          </Row>
          <Tabs
            defaultActiveKey="drafts"
            id="uncontrolled-tab-example"
            className="mb-3 mt-3"
          >
            <Tab eventKey="drafts" title={t("Backend.Post.Tabs.Drafts")}>
              <div className="verti-timeline left-timeline">
                <TimnelineTab
                  posts={drafts}
                  editAction={editAction}
                  deleteAction={deleteAction}
                  setDraft={setDraft}
                  setEditPost={setEditPost}
                  setShowVariants={setShowVariants}
                  publish={publish}
                  setPosts={setDrafts}
                  markInProgress={markInProgress}
                  setActiveKey={setActiveKey}
                />
              </div>
            </Tab>
            <Tab
              eventKey="inprogress"
              title={t("Backend.Post.Tabs.InProgress")}
            >
              <div className="verti-timeline left-timeline">
                <TimnelineTab
                  posts={inProgress}
                  editAction={editAction}
                  deleteAction={deleteAction}
                  setDraft={setDraft}
                  setEditPost={setEditPost}
                  setShowVariants={setShowVariants}
                  publish={publish}
                  setPosts={setInProgress}
                  markInProgress={markInProgress}
                  setActiveKey={setActiveKey}
                />
              </div>
            </Tab>
            <Tab
              eventKey="unschedule"
              title={t("Backend.Post.Tabs.ReadyUnschedule")}
            >
              <div className="verti-timeline left-timeline">
                <TimnelineTab
                  posts={unscheduled}
                  editAction={editAction}
                  deleteAction={deleteAction}
                  setDraft={setDraft}
                  setEditPost={setEditPost}
                  setShowVariants={setShowVariants}
                  publish={publish}
                  setPosts={setUnscheduled}
                  markInProgress={markInProgress}
                  setActiveKey={setActiveKey}
                />
              </div>
            </Tab>
            <Tab eventKey="planned" title={t("Backend.Post.Tabs.Ready")}>
              <div className="verti-timeline left-timeline">
                <TimnelineTab
                  posts={planned}
                  editAction={editAction}
                  deleteAction={deleteAction}
                  setDraft={setDraft}
                  setEditPost={setEditPost}
                  setShowVariants={setShowVariants}
                  publish={publish}
                  setPosts={setPlanned}
                  useSchedule={true}
                  markInProgress={markInProgress}
                  setActiveKey={setActiveKey}
                />
              </div>
            </Tab>
            <Tab eventKey="published" title={t("Backend.Post.Tabs.Published")}>
              <div className="verti-timeline left-timeline">
                <TimnelineTab
                  posts={published}
                  editAction={editAction}
                  deleteAction={deleteAction}
                  setDraft={setDraft}
                  setEditPost={setEditPost}
                  setShowVariants={setShowVariants}
                  publish={publish}
                  setPosts={setPublished}
                  useSchedule={true}
                  markInProgress={markInProgress}
                  setActiveKey={setActiveKey}
                />
              </div>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  );
};

export default Timeline;
