import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Schedule from "@serie3/mymediaplanner/Modules/Social/Entity/Schedule";
import NewModal from "../../../shared/Modals/NewModal";
import SaveButton from "../../../shared/Buttons/SaveButton";
import DateTimeField from "../../../Form/DateTimeField";
import Timezones from "../../../Form/Timezones";
import SelectField from "../../../Form/SelectField";
import ScheduleForm from "@serie3/mymediaplanner/Modules/Social/Forms/ScheduleForm";

const ScheduleFormView = ({
  formik,
  showPlanningForm,
  setshowPlanningForm,
  editData,
  CustomSaveButton = null,
}) => {
  const { t } = useTranslation();

  const Save = () => {
    return CustomSaveButton ? (
      <CustomSaveButton/>
    ) : (
      <SaveButton formik={formik} />
    );
  };

  return (
    <NewModal
      title={t("Backend.Post.Schedule.ModalTitle")}
      show={showPlanningForm}
      setShow={setshowPlanningForm}
      onExit={() => {
        formik.resetForm({ values: { Schedule } });
      }}
      onShow={() => {
        editData
          ? formik.setValues(ScheduleForm.setEditData(editData))
          : formik.setValues(Schedule);
      }}
      ExtraButton={Save}
    >
      <Row>
        <Col>
          <DateTimeField
            formik={formik}
            label={t("Backend.Post.Schedule.ScheduleFor")}
            valueName={"local_user_time"}
          />
        </Col>
        <Col>
          <Timezones formik={formik} />
        </Col>
        <Col>
          <SelectField
            formik={formik}
            valueName={"platform"}
            label={t("Backend.Post.Schedule.Platformname")}
          >
            <option value={"linkedin"}>LinkedIn</option>
          </SelectField>
        </Col>
      </Row>
    </NewModal>
  );
};

export default ScheduleFormView;
