import Idea from "../Entity/Idea";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createIdeaForm = (dataList, setDataFunction, setAdditionalData) => {
    const validationSchema = Yup.object({
      title: Yup.string().required("Required"),
      text: Yup.string().required("Required"),
    });
  const editFunction = async (values, originalValues) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(
      SocialEndpoints.posts.ideas.edit,
      payload
    );

    let originalPayload = JSON.stringify(originalValues, null, 2);
    listHandler.updateList(
      response,
      originalPayload,
      dataList,
      setDataFunction
    );
  };

  const onSubmit = async (values) => {
    const originalValues = { ...values };
    if (values.id > 0) {
      editFunction(values, originalValues);
    } else {
      console.log(values);
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        SocialEndpoints.posts.ideas.new,
        payload
      );
      
      if (
        typeof dataList !== "undefined" &&
        typeof setDataFunction === "function"
      ) {
        listHandler.newToList(response, dataList, setDataFunction, null, true);
        values.id = response.data.id;
      }
    }
  };

  return {
    initialValues: Idea,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Idea = {
    id: editData.id,
    title: editData.title,
    text: editData.text,
    content_type: editData.content_type,
    tone: editData.tone,
    topic: editData.topic,
    platform: editData.platform,
  };

  return Idea;
};

export default { createIdeaForm, setEditData };
