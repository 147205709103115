const ConnectionRequest = {
    url: '',
    name: '',
    withdrawn: false,
    platform: '',
    sendDate: '',
    follower: 0,
    bio: '',
};

export default ConnectionRequest;
