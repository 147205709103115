import { useTranslation } from "react-i18next";
import { Row, Col, Container, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FingerPrint from "../../../../assets/images/landingpage/Icons/fingerPrint.svg";
import LightBulb from "../../../../assets/images/landingpage/Icons/lightbulb.svg";
import SheetIcon from "../../../../assets/images/landingpage/Icons/sheetIcon.svg";
import Speedometer from "../../../../assets/images/landingpage/Icons/speedometer.svg";
import TeamIcon from "../../../../assets/images/landingpage/Icons/teamIcon.svg";
import ConnectIcon from "../../../../assets/images/landingpage/Icons/connectIcon.svg";
import Arrow from "../../../../assets/images/landingpage/arrow.svg";
import FeatuerBox from "./FeatuerBox";
import { useRef } from "react";

const TargetGroups = ({ props }) => {
  const { t } = useTranslation();
  const carouselRef = useRef(null);
  const responsiveFeatures = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 12,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleNext = () => {
    carouselRef.current.next();
  };

  const handlePrev = () => {
    carouselRef.current.previous();
  };

  
  return (
    <div className="hero2Container">
      <Container>
        <h3>{t("Landingpage.TargetGroup.Header")}</h3>
        <p>{t("Landingpage.TargetGroup.Text")}</p>
      </Container>
      <Container>
        <Row>
          <Carousel
            ref={carouselRef}
            autoPlay={false}
            infinite={true}
            responsive={responsiveFeatures}
            interval={null}
            indicators={false}
            arrows={false} // Disable default arrows
            customLeftArrow={null}
            customRightArrow={null}
          >
            <FeatuerBox
              image={LightBulb}
              title={t("Landingpage.TargetGroup.Accordion.Starter.Header")}
              text={t("Landingpage.TargetGroup.Accordion.Starter.Text")}
            />
            <FeatuerBox
              image={Speedometer}
              title={t("Landingpage.TargetGroup.Accordion.Pro.Header")}
              text={t("Landingpage.TargetGroup.Accordion.Pro.Text")}
            />
            <FeatuerBox
              image={TeamIcon}
              title={t("Landingpage.TargetGroup.Accordion.Team.Header")}
              text={t("Landingpage.TargetGroup.Accordion.Team.Text")
               
              }
            />
            <FeatuerBox
              image={SheetIcon}
              title={t("Landingpage.TargetGroup.Accordion.Coach.Header")}
              text={t("Landingpage.TargetGroup.Accordion.Coach.Text")}
            />
            <FeatuerBox
              image={FingerPrint}
              title={t("Landingpage.TargetGroup.Accordion.Expert.Header")}
              text={t("Landingpage.TargetGroup.Accordion.Expert.Text")}
            />
            <FeatuerBox
              image={ConnectIcon}
              title={t("Landingpage.TargetGroup.Accordion.Custom.Header")}
              text={t("Landingpage.TargetGroup.Accordion.Custom.Text")}
            />
          </Carousel>
        </Row>
        <Row style={{ marginTop: "60px" }}>
          <Col className="d-flex justify-content-center">
            <Button onClick={handlePrev} style={{ marginRight: "20px" }}>
              <img src={Arrow} style={{ transform: "scaleX(-1)" }} />
            </Button>
            <Button
              onClick={handleNext}
              style={{ background: "var(--Color-yellow, #F34E4E)" }}
            >
              <img src={Arrow} />
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TargetGroups;
