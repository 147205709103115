import ConnectionRequest from "../Entity/ConnectionRequest";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createConnectionRequestForm = (dataList, setDataFunction, setAdditionalData) => {
  const editFunction = async (values, originalValues) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(
      SocialEndpoints.connections.edit,
      payload
    );

    let originalPayload = JSON.stringify(originalValues, null, 2);
    listHandler.updateList(
      response,
      originalPayload,
      dataList,
      setDataFunction
    );
  };

  const onSubmit = async (values) => {
    const originalValues = { ...values };
    if (values.id > 0) {
      editFunction(values, originalValues);
    } else {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        SocialEndpoints.connections.new,
        payload
      );
      
      if (
        typeof dataList !== "undefined" &&
        typeof setDataFunction === "function"
      ) {
        listHandler.newToList(response, dataList, setDataFunction, null, true);
      }
    }
  };

  return {
    initialValues: ConnectionRequest,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let ConnectionRequest = {
    id: editData.id,
    url: editData.url,
    name: editData.name,
    withdrawn: editData.withdrawn,
    platform: editData.platform,
    follower: editData.follower,
    bio: editData.bio,
    send_date: editData.send_date,
  };

  return ConnectionRequest;
};

export default { createConnectionRequestForm, setEditData };
