import AiPlanningAssistant from "../Entity/AiPlanningAssistant";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createAiPlanningAssistantForm = (
  dataList,
  setDataFunction,
  setAdditionalData
) => {
  const onSubmit = async (values) => {
    const transformedValues = {
      ...values,
      pillars: values.pillars.map((pillar) => ({ id: pillar.id })),
      month: `${values.month}-01T11:00`, // Ensures format yyyy-MM-01T12:00
    };

    let payload = JSON.stringify(transformedValues, null, 2);
    const response = await CrudApi.createNew(
      SocialEndpoints.posts.planningAi.assistant,
      payload
    );
    if(response.ok){
      setDataFunction(response.data.schedules);
    }
  };

  return {
    initialValues: AiPlanningAssistant,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

export default { createAiPlanningAssistantForm };
