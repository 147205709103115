import ContentPillar from "../Entity/ContentPillar";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createContentPillarForm = (dataList, setDataFunction, setAdditionalData) => {
    const editFunction = async (values, originalValues) => {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        SocialEndpoints.posts.contentPillars.edit,
        payload
      );
  
      let originalPayload = JSON.stringify(originalValues, null, 2);
      listHandler.updateList(
        response,
        originalPayload,
        dataList,
        setDataFunction
      );
    };
  
    const onSubmit = async (values) => {
      const originalValues = { ...values };
      if (values.id > 0) {
        editFunction(values, originalValues);
      } else {
        let payload = JSON.stringify(values, null, 2);
        const response = await CrudApi.createNew(
          SocialEndpoints.posts.contentPillars.new,
          payload
        );
        
        if (
          typeof dataList !== "undefined" &&
          typeof setDataFunction === "function"
        ) {
          listHandler.newToList(response, dataList, setDataFunction, null, true);
        }
      }
    };
  
    return {
      initialValues: ContentPillar,
      onSubmit: (values, actions) => {
        onSubmit(values);
      },
    };
  };
  
  const setEditData = (editData) => {
    let ContentPillar = {
        id: editData.id,
        pillar: editData.pillar,
        description: editData.description,
        subcategories: editData.subcategories,
        active: editData.active
    };
  
    return ContentPillar;
  };
  
  export default { createContentPillarForm, setEditData };
  