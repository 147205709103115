import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import AlexanderKolin from "../../../../assets/images/landingpage/SocialProof/alexanderKolin.jpeg";
import AndreasHahn from "../../../../assets/images/landingpage/SocialProof/andreasHahn.jpeg";
import Nadia from "../../../../assets/images/landingpage/SocialProof/Nadia.jpeg";
import Pierre from "../../../../assets/images/landingpage/SocialProof/pierre.jpeg";
import StephanHarenkamp from "../../../../assets/images/landingpage/SocialProof/stephanharenkamp.jpeg";
import FelixDziekan from "../../../../assets/images/landingpage/SocialProof/felixDziekan.jpg";
import ValentynaLevchuk from "../../../../assets/images/landingpage/SocialProof/valentynalevchuck.jpeg";
import Carousel from "react-multi-carousel";
import UserProof from "./UserProof";
import "react-multi-carousel/lib/styles.css";

const SocialProof = ({ props }) => {
  const { t } = useTranslation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 12,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h3 style={{ textAlign: "left" }} className="mb-5">
            {t("Landingpage.SocialProof.Header")}
            </h3>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ marginBottom: "100px" }}>
        <Row>
          <div className="carousel-container">
            <Carousel
              arrows={false}
              autoPlay
              centerMode={true}
              className=""
              containerClass="container-with-dots"
              customTransition="all 5s linear"
              dotListClass=""
              draggable={true}
              focusOnSelect={false}
              infinite={true}
              keyBoardControl={false}
              minimumTouchDrag={80}
              pauseOnHover={true}
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={responsive}
            >
              <UserProof
                title={"CTO / Senior Software Consultant"}
                name={"Felix Dziekan"}
                picture={FelixDziekan}
                url={"https://www.linkedin.com/in/felixdziekan/"}
              />
              <UserProof
                title={
                  "Leadership | Intelligence and Analysis | Arabic - English"
                }
                name={"Nadia Ismail"}
                picture={Nadia}
                url={"https://www.linkedin.com/in/nadia-ismail-citp-b5365872/"}
              />
              <UserProof
                title={
                  "Als IT Freelancer Kunden ohne Vermittler gewinnen | 1:1 Begleitung "
                }
                name={"Pierre Wilken"}
                picture={Pierre}
                url={"https://www.linkedin.com/in/pierrewilken/"}
              />
              <UserProof
                title={"Java-Entwickler"}
                name={"Stefan Harenkamp"}
                picture={StephanHarenkamp}
                url={"https://www.linkedin.com/in/stefan-harenkamp/"}
              />
              <UserProof
                title={
                  "Project manager. Helping teams and business achieve their goals"
                }
                name={"Valentyna Levchuk"}
                picture={ValentynaLevchuk}
                url={"https://www.linkedin.com/in/valentyna-levchuk-2a89413b/"}
              />
              <UserProof
                title={"Java Backend Engineer"}
                name={"Alexander Kolin"}
                picture={AlexanderKolin}
                url={"https://www.linkedin.com/in/alexander-kolin/"}
              />
              <UserProof
                title={"Embedded Software, Industrieautomatisierung & IoT"}
                name={"Andreas Hahn"}
                picture={AndreasHahn}
                url={"https://www.linkedin.com/in/andreas-hahn-05393844/"}
              />
              <UserProof
                title={"CTO / Senior Software Consultant"}
                name={"Felix Dziekan"}
                picture={FelixDziekan}
                url={"https://www.linkedin.com/in/felixdziekan/"}
              />
              <UserProof
                title={
                  "Leadership | Intelligence and Analysis | Arabic - English"
                }
                name={"Nadia Ismail"}
                picture={Nadia}
                url={"https://www.linkedin.com/in/nadia-ismail-citp-b5365872/"}
              />
              <UserProof
                title={
                  "Als IT Freelancer Kunden ohne Vermittler gewinnen | 1:1 Begleitung "
                }
                name={"Pierre Wilken"}
                picture={Pierre}
                url={"https://www.linkedin.com/in/pierrewilken/"}
              />
              <UserProof
                title={
                  "Project manager. Helping teams and business achieve their goals"
                }
                name={"Valentyna Levchuk"}
                picture={ValentynaLevchuk}
                url={"https://www.linkedin.com/in/valentyna-levchuk-2a89413b/"}
              />
              <UserProof
                title={"Java-Entwickler"}
                name={"Stephan Harenkamp"}
                picture={StephanHarenkamp}
                url={"https://www.linkedin.com/in/stefan-harenkamp/"}
              />
              <UserProof
                title={"Java Backend Engineer"}
                name={"Alexander Kolin"}
                picture={AlexanderKolin}
                url={"https://www.linkedin.com/in/alexander-kolin/"}
              />
              <UserProof
                title={"Embedded Software, Industrieautomatisierung & IoT"}
                name={"Andreas Hahn"}
                picture={AndreasHahn}
                url={"https://www.linkedin.com/in/andreas-hahn-05393844/"}
              />
              <UserProof
                title={
                  "Leadership | Intelligence and Analysis | Arabic - English"
                }
                name={"Nadia Ismail"}
                picture={Nadia}
                url={"https://www.linkedin.com/in/nadia-ismail-citp-b5365872/"}
              />
              <UserProof
                title={
                  "Als IT Freelancer Kunden ohne Vermittler gewinnen | 1:1 Begleitung "
                }
                name={"Pierre Wilken"}
                picture={Pierre}
                url={"https://www.linkedin.com/in/pierrewilken/"}
              />
              <UserProof
                title={"Java-Entwickler"}
                name={"Stephan Harnekamp"}
                picture={StephanHarenkamp}
                url={"https://www.linkedin.com/in/stefan-harenkamp/"}
              />
              <UserProof
                title={"Java Backend Engineer"}
                name={"Alexander Kolin"}
                picture={AlexanderKolin}
                url={"https://www.linkedin.com/in/alexander-kolin/"}
              />
              <UserProof
                title={"Embedded Software, Industrieautomatisierung & IoT"}
                name={"Andreas Hahn"}
                picture={AndreasHahn}
                url={"https://www.linkedin.com/in/andreas-hahn-05393844/"}
              />
            </Carousel>
            <div className="carousel-inner-shadow"></div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SocialProof;
