const SocialEndpoints = {
  linkedin: {
    isConnected: "/social/linkedin/isconnected",
    connect: "/social/linkedin/connect",
    disconnect: "/social/linkedin/disconnect",
  },
  connections: {
    index: "social/requests/",
    edit: "social/requests/edit",
    show: "social/requests/show/",
    delete: "social/requests/delete/"
  },
  posts: {
    index: "social/post/",
    plannedOnly: "social/post/plannedonly",
    postedOnly: "social/post/postedonly",
    drafts: "social/post/drafts",
    new: "social/post/new",
    edit: "social/post/edit",
    delete: "social/post/delete/",
    show: "social/post/show/",
    setDraft: "social/post/setDraft/",
    markInProgress: "social/post/markinprogress/",
    schedule: {
      indexAllForPost: "social/post/schedule/all/",
      index: "social/post/schedule/",
      new: "social/post/schedule/new",
      edit: "social/post/schedule/edit",
      show: "social/post/schedule/show/",
      delete: "social/post/schedule/delete/",
    },
    publish: {
      linkedin: "social/linkedin/publish/",
    },
    postImage: "social/post/postimage/",
    ai: {
      finishPost: "ai/content/finishPost/",
      checkText: "ai/content/check/",
      createContentVariant: "ai/content/createcontentvariant/",
      createTypeVariant: "ai/content/createtypevariant/",
      createEducationalBlogpost: "ai/content/createeducationalblogpost/",
      createPostFromBulletpoints: "ai/content/createpostfrombulletpoints/",
      creatAassistedPost: "ai/content/createassistedpost/",
      createNewPost: "ai/content/createnewpost",
      createIdeas: "ai/content/createIdeas",
    },
    imageAi: {
      autoenhance: "ai/images/enhanceImage/",
      denoise: "ai/images/denoise/",
      facelift: "ai/images/facelift/",
    },
    planningAi: {
      assistant: "ai/content/planmonth"
    },
    ideas: {
      index: "social/idea/",
      new: "social/idea/new",
      edit: "social/idea/edit",
      delete: "social/idea/delete/",
      show: "social/idea/show/",
      convert: "social/idea/convert/",
    },
    poll: {
      new: "social/poll/new/",
      edit: "social/poll/edit",
      delete: "social/poll/delete/",
    },
    contentPillars: {
      index: "social/contentpillar/",
      new: "social/contentpillar/new",
      edit: "social/contentpillar/edit",
      show: "social/contentpillar/show/",
      showposts: "social/contentpillar/showposts/",
      delete: "social/contentpillar/delete/",
      deleteFromPillar: "social/contentpillar/removeFromPillar/",
    },
    dataField: "posts",
    scheduledDataField: "schedules",
  },
};

export default SocialEndpoints;
