import crudApi from "../../api/crudApi";
import endpoints from "../../config/endpoints";

const createRequestValidationForm = (setSuccess) => {
  const onSubmit = async (values, actions) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await crudApi.createNew(
      endpoints.register.request,
      payload, 
      null,
      true
    );
    setSuccess(true);
  };

  return {
    initialValues: { email: "" },
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

export default createRequestValidationForm;
