import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import CustomEvent from "./components/CustomEvent";
import NewModal from "../shared/Modals/NewModal";
import LinkedinPreview from "./components/Previews/LinkedinPreview";
import { Card } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentsEndpoints from "@serie3/mymediaplanner/Modules/Documents/endpoints";
import Schedule from "@serie3/mymediaplanner/Modules/Social/Entity/Schedule";
import ScheduleForm from "@serie3/mymediaplanner/Modules/Social/Forms/ScheduleForm";
import { useFormik } from "formik";
import ScheduleFormView from "./components/Schedules/ScheduleFormView";
import PostFormView from "./components/PostFormView";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import listHandler from "@serie3/common/Domain/listHandler";
import AiPlanningAssistantView from "./components/SubComponents/AiPlanningAssistantView";

// Setup moment for date localization
const localizer = momentLocalizer(moment);

const PostCalendar = ({
  schedules = [],
  setSchedules,
  dontUseCalendarClick = false,
  customDeleteFunction = null,
  savePlan = null,
  date
}) => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [viewPost, setViewPost] = useState(null);
  const [viewPostId, setViewPostId] = useState(null);
  const [showPreviewPost, setShowPreviewPost] = useState(false);
  const [postFiles, setPostFiles] = useState([]);
  const [showPlanningForm, setShowPlanningForm] = useState();
  const [editDataSchedule, setEditDataSchedule] = useState();
  const [editPost, setEditPost] = useState();
  const [showEditPost, setShowEditPost] = useState();
  const [showAiPlanner, setShowAiPlanner] = useState(false);
  const [recommendation, setRecommendation] = useState(null);
  const [showRecommendation, setShowRecommendation] = useState(false);

  useEffect(() => {
    const setCalendarEntry = (schedules) => {
      const calendarEntry = [];
      schedules.map((schedule, index) => {
        let scheduleDate = schedule.scheduled_for;

        if (typeof scheduleDate === "undefined") {
          scheduleDate = schedule.posted_at;
        }

        const startDate = new Date(scheduleDate);
        const types = [];

        types.push(schedule.post.post_type);
        types.push(schedule.post.content_type);

        let status = schedule.post_status;
        if (typeof status === "undefined") {
          status = "planned";
        }

        const entry = {
          id: schedule.post.id,
          scheduleId: schedule.id,
          title: schedule.post.title,
          text: schedule.post.text,
          postDate: schedule.scheduled_for,
          timezone: schedule.timezone,
          start: new Date(startDate),
          end: new Date(startDate.getTime() + 60 * 60 * 1000),
          status: status,
          types: types,
          contentType: schedule.post.content_type,
          stats: schedule.post.stats?.at(-1),
          planning: schedule.planning ? true : false,
          draft: schedule.post.draft
        };
        calendarEntry.push(entry);
      });
      setEvents(calendarEntry);
    };

    setCalendarEntry(schedules);
  }, [schedules]);

  useEffect(() => {
    if (viewPostId > 0) {
      const fetchViewPost = async () => {
        const data = await crudApi.show(SocialEndpoints.posts.show, viewPostId);
        if (data) {
          setViewPost(data);
          setEditPost(data);
          setShowEditPost(true);
        }
      };

      const loadPostDocuments = async () => {
        const response = await crudApi.getAll(
          DocumentsEndpoints.showPostFiles + viewPostId
        );
        if (response.ok) {
          setPostFiles(response.data);
        }
      };

      if (viewPostId != null) {
        fetchViewPost();
        loadPostDocuments();
      }
    }
  }, [viewPostId]);

  const eventStyleGetter = (event) => {
    let borderColor = "#3174ad"; // Default color

    // Check if event has certain types
    if (event.status.includes("error")) {
      borderColor = "#f56c6c"; // Red for meetings
    }
    if (event.status.includes("scheduled")) {
      borderColor = "#e6a23c"; // Orange for important events
    }
    if (event.status.includes("published")) {
      borderColor = "#67c23a"; // Green for reviews
    }

    const style = {
      borderLeft: `10px solid ${borderColor}`,
      backgroundColor: "#fff",
      borderRadius: "5px",
      color: "black",
      border: "1px solid #ddd",
      display: "block",
      paddingLeft: "0",
    };

    return {
      style,
    };
  };

  const scheduleForm = ScheduleForm.createScheduleForm(
    schedules,
    setSchedules,
    null,
    null
  );
  const scheduleFormik = useFormik(scheduleForm);

  const createScheduleWithPost = (start) => {
    let schedule = { ...Schedule };
    schedule.local_user_time = start;
    setEditDataSchedule(schedule);
  };

  const handleSelectSlot = ({ start, end, slots }) => {
    if (!dontUseCalendarClick) {
      const endDate = moment(end).format("YYYY-MM-DD");

      const datePart = moment(start).format("YYYY-MM-DD");
      const timePart = moment().format("HH:mm");
      const startDate = `${datePart}T${timePart}`;

      createScheduleWithPost(startDate);
      setShowPlanningForm(true);
    }
  };

  const savePostAndSchedule = async () => {
    let response = await crudApi.createNew(SocialEndpoints.posts.new, {
      draft: false,
    });
    if (response.ok) {
      setEditPost(response.data);
      return response.data.id;
    }
  };

  const SaveSchedule = () => {
    return (
      <Button
        onClick={async (e) => {
          e.preventDefault();
          const postID = await savePostAndSchedule();
          const newValues = { ...scheduleFormik.values };
          newValues.post = { id: postID };
          const response = await crudApi.createNew(
            SocialEndpoints.posts.schedule.new,
            newValues
          );
          listHandler.newToList(response, schedules, setSchedules, null);
          if (response.ok) {
            setShowPlanningForm(false);
            setViewPostId(postID);
          }
        }}
      >
        {t("Backend.Buttons.Save")}
      </Button>
    );
  };

  const customTrigger = async (triggerValue) => {
    //This is needed because the form inverses the trigger Value
    let originalID = triggerValue * -1;
    const event = events.find((obj) => obj.id === originalID);
    if (!event.planning) {
      const response = await crudApi.justGet(
        SocialEndpoints.posts.schedule.show + event.scheduleId
      );
      listHandler.replaceItemInList(response.data, schedules, setSchedules);
    }
    setViewPostId(-1);
  };

  const deletePlan = async (schedule) => {
    const scheduleId = schedule.scheduleId;
    const response = await crudApi.justGet(
      SocialEndpoints.posts.schedule.show + scheduleId
    );
    const deleteResponse = await crudApi.del(
      SocialEndpoints.posts.schedule.delete,
      scheduleId
    );
    if (deleteResponse.ok) {
      const updatedList = listHandler.deleteWithoutCall(
        response.data,
        schedules
      );
      setSchedules([...updatedList]);
    }
  };

  const StartAiPlannerButton = () => {
    return (
      <Button
        onClick={() => {
          setShowAiPlanner(true);
        }}
      >
        Use AI Planner
      </Button>
    );
  };

  return (
    <>
      <NewModal
        show={showRecommendation}
        setShow={setShowRecommendation}
        title={recommendation && recommendation.title}
      >
        {recommendation && <>{recommendation.text}</>}
      </NewModal>
      <AiPlanningAssistantView
        show={showAiPlanner}
        setShow={setShowAiPlanner}
      />
      <PostFormView
        showEdit={showEditPost}
        setShowEdit={setShowEditPost}
        editData={editPost}
        trigger={viewPostId}
        setTrigger={customTrigger}
      />
      <ScheduleFormView
        formik={scheduleFormik}
        showPlanningForm={showPlanningForm}
        setshowPlanningForm={setShowPlanningForm}
        editData={editDataSchedule}
        CustomSaveButton={() => <SaveSchedule formik={scheduleFormik} />}
      />
      <NewModal
        show={showPreviewPost}
        setShow={setShowPreviewPost}
        onHide={() => {
          setViewPost(null);
          setViewPostId(null);
          setPostFiles(null);
        }}
      >
        <Card>
          <Card.Body>
            <Tabs
              defaultActiveKey="linkedIn"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="linkedIn"
                title={<FontAwesomeIcon icon={faLinkedin} size="2x" />}
                style={{
                  backgroundColor: "#f4f2ee",
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}
              >
                {viewPost && (
                  <LinkedinPreview
                    text={viewPost.text}
                    postFiles={postFiles}
                    poll={viewPost.poll}
                  />
                )}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </NewModal>
      <Row className="mb-5">
        <Col>
          {savePlan ? (
            <Button onClick={() => savePlan()}>Save Plan</Button>
          ) : (
            <StartAiPlannerButton />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Calendar
            selectable
            onSelectSlot={handleSelectSlot}
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 1000 }}
            date={date}
            eventPropGetter={eventStyleGetter}
            toolbar={!date}
            components={{
              event: (props) => (
                <CustomEvent
                  {...props}
                  setViewId={(event) => {
                    if (event.id) {
                      setViewPostId(event.id);
                    } else {
                      setRecommendation(event);
                      setShowRecommendation(true);
                    }
                  }}
                  deletePlan={
                    customDeleteFunction ? customDeleteFunction : deletePlan
                  }
                />
              ),
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PostCalendar;
