import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import FileListItem from "./components/Filemanager/FileListItem";
import DocumentsEndpoints from "@serie3/mymediaplanner/Modules/Documents/endpoints";
import NewButtonWithIcon from "../shared/Buttons/NewButtonWithIcon";
import NewModal from "../shared/Modals/NewModal";
import FileDropzone from "../shared/Modals/FileDropzone";
import listHandler from "@serie3/common/Domain/listHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

const Filemanager = ({ handleFileFunction = null }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [textContent, setTextContent] = useState(null); // State for text file content
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    const loadFiles = async () => {
      const response = await crudApi.justGet(DocumentsEndpoints.index);
      setFiles(response.data.documents);
    };

    loadFiles();
  }, []);

  useEffect(() => {
    if (previewFile && previewFile.type.startsWith("text/")) {
      const loadTextContent = async () => {
        const response = await fetch(previewFile.url);
        const text = await response.text();
        setTextContent(text);
      };
      loadTextContent();
    }
  }, [previewFile]);

  const deleteFile = (index, file) => {
    listHandler.deleteFromList(
      index,
      crudApi.del(DocumentsEndpoints.delete, file.id),
      setFiles,
      files
    );
  };

  const showFile = async (file) => {
    const fileResponse = await crudApi.getBinary(
      DocumentsEndpoints.show + file.id
    );

    // Create a Blob from the response data and detect the file type
    const blob = new Blob([fileResponse.data], {
      type: fileResponse.headers["content-type"],
    });
    const url = URL.createObjectURL(blob);

    setPreviewTitle(file.original_name);
    setPreviewFile({ url, type: fileResponse.headers["content-type"] }); // Store both URL and type
    setShowFilePreview(true); // Show the modal
  };

  return (
    <>
      <NewModal
        show={showFilePreview}
        setShow={setShowFilePreview}
        title={previewTitle}
      >
        {previewFile && previewFile.type.startsWith("image/") && (
          <img
            src={previewFile.url}
            alt="Preview"
            style={{ maxWidth: "100%" }}
          />
        )}
        {previewFile && previewFile.type === "application/pdf" && (
          <iframe
            src={previewFile.url}
            width="100%"
            height="600px"
            title="PDF Preview"
          ></iframe>
        )}
        {previewFile && previewFile.type.startsWith("text/") && (
          <pre>{textContent}</pre> // Show text content from state
        )}

        {previewFile && previewFile.type.startsWith("video/") && (
          <video controls width="100%">
            <source src={previewFile.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {!previewFile?.type.startsWith("image/") &&
          previewFile?.type !== "application/pdf" &&
          previewFile?.type.startsWith("text/") && (
            <a
              href={previewFile.url}
              download={`file.${previewFile.type.split("/")[1]}`}
            >
              Download file
            </a>
          )}
      </NewModal>
      <NewModal show={showFileUpload} setShow={setShowFileUpload}>
        <FileDropzone
          uploadUrl={DocumentsEndpoints.new}
          setName={false}
          setData={setFiles}
          data={files}
          edit={false}
        />
      </NewModal>
      <Row>
        <Col>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Filemanager</h4>
          </div>
        </Col>
      </Row>
      <div className="d-xl-flex">
        <div className="w-100">
          <div className="d-md-flex">
            <div className="filemanager-sidebar me-md-3 card">
              <div className="card-body">
                <div className="mb-4">
                  <ul className="list-unstyled categories-list">
                    <li>
                      <a className="text-body d-flex align-items-center">
                        <FontAwesomeIcon icon={faFolder} className="me-2" />
                        Local Files
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-100">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <NewButtonWithIcon
                        color="primary"
                        title={t("Backend.Buttons.Add")}
                        handleClick={() => setShowFileUpload(true)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <div className="mt-4">
                      <div className="d-flex flex-wrap">
                        <h5 className="font-size-16 me-3">Recent Files</h5>
                        <div className="ms-auto">
                          <a className="fw-medium text-reset" href="#">
                            View All
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <Table className="table align-middle table-nowrap table-hover mb-0 table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Size</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {files.map((file, index) => {
                            return (
                              <FileListItem
                                file={file}
                                deleteFile={deleteFile}
                                viewFile={showFile}
                                handleFileFunction={handleFileFunction}
                                index={index}
                              />
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filemanager;
