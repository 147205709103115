import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import crudApi from "@serie3/common/api/crudApi";
import NewModal from "../../../shared/Modals/NewModal";
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  CardBody,
} from "react-bootstrap";
import AiPlanningAssistant from "@serie3/mymediaplanner/Modules/Social/Forms/AiPlanningAssistant";
import { useFormik } from "formik";
import DateField from "../../../Form/DateField";
import NumberField from "../../../Form/NumberField";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import PostCalendar from "../../PostCalendar";
import listHandler from "@serie3/common/Domain/listHandler";
import Timezones from "../../../Form/Timezones";

const AiPlanningAssistantView = ({ show, setShow }) => {
  const { t } = useTranslation();
  const [slideIndex, setSlideIndex] = useState(0);
  const [schedules, setSchedules] = useState([]);
  const [stepTitle, setStepTitle] = useState(
    t("Backend.Post.Assistant.AiPlanner.Titles.Month")
  );
  const [pillars, setPillars] = useState([]);
  const [selectedPillars, setSelectedPillars] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const AiPlanningAssistantForm =
    AiPlanningAssistant.createAiPlanningAssistantForm(schedules, setSchedules);
  const formik = useFormik(AiPlanningAssistantForm);


  useEffect(() => {
    if (slideIndex === 0) {
      setStepTitle(t("Backend.Post.Assistant.AiPlanner.Titles.Month"));
    }

    if (slideIndex === 1) {
      setStepTitle(t("Backend.Post.Assistant.AiPlanner.Titles.Interval"));
    }

    if (slideIndex === 2) {
      setStepTitle(t("Backend.Post.Assistant.AiPlanner.Titles.Days"));
    }

    if (slideIndex === 3) {
      setStepTitle(t("Backend.Post.Assistant.AiPlanner.Titles.UsePosts"));
    }

    if (slideIndex === 4) {
      setStepTitle(t("Backend.Post.Assistant.AiPlanner.Titles.UsePillars"));

      const fetchPillars = async () => {
        await crudApi.getAll(
          SocialEndpoints.posts.contentPillars.index,
          setPillars,
          "contentpillars"
        );
      };
      fetchPillars();
    }

    if (slideIndex === 5) {
      setStepTitle(t("Backend.Post.Assistant.AiPlanner.Titles.Sum"));
    }
  }, [slideIndex]);

  const savePlan = async () => {
    for (const schedule of schedules) {
      let postId = schedule.post.id ? schedule.post.id : null;

      if (!postId) {
        let response = await crudApi.createNew(SocialEndpoints.posts.new, {
          title: schedule.post.title,
          text: schedule.post.text,
        });

        if (response.ok) {
          postId = response.data.id;
        }
      }

      await crudApi.createNew(SocialEndpoints.posts.schedule.new, {
        post: { id: postId },
        local_user_time: schedule.scheduled_for,
        timezone: "Europe/Berlin",
      });
    }
  };

  const NavButtons = () => {
    return (
      <>
        {slideIndex > 0 && (
          <Button
            className="btn btn-danger"
            onClick={() => {
              setSlideIndex(slideIndex - 1);
              setFullScreen(false);
            }}
          >
            {t("Backend.Post.Assistant.Back")}
          </Button>
        )}
        {slideIndex === 0 && formik.values["month"] && (
          <Button
            className="btn btn-success"
            onClick={async () => {
              setSlideIndex(1);
            }}
          >
            {t("Backend.Buttons.Next")}
          </Button>
        )}

        {slideIndex === 1 && formik.values["interval"] > 0 && (
          <Button
            className="btn btn-success"
            onClick={async () => {
              setSlideIndex(2);
            }}
          >
            {t("Backend.Buttons.Next")}
          </Button>
        )}

        {slideIndex === 2 && (
          <Button
            className="btn btn-success"
            onClick={async () => {
              setSlideIndex(3);
            }}
          >
            {t("Backend.Buttons.Next")}
          </Button>
        )}

        {slideIndex === 4 && (
          <Button
            className="btn btn-success"
            onClick={async () => {
              setSlideIndex(5);
            }}
          >
            {t("Backend.Buttons.Check")}
          </Button>
        )}
        {slideIndex === 5 && (
          <Button
            className="btn btn-success"
            onClick={async () => {
              formik.submitForm();
              setSlideIndex(6);
              setFullScreen(true);
            }}
          >
            {t("Backend.Buttons.Plan")}
          </Button>
        )}
      </>
    );
  };

  const deletePlan = (item) => {
    const deleteItem = { id: item.scheduleId };
    const updatedList = listHandler.deleteWithoutCall(deleteItem, schedules);
    setSchedules([...updatedList]);
  };

  return (
    <NewModal
      setShow={setShow}
      show={show}
      title={stepTitle}
      ExtraButton={NavButtons}
      onExit={() => {
        console.log(formik.values);
        setSlideIndex(0);
        formik.resetForm();
        setSelectedPillars([]);
        setFullScreen(false);
        setSchedules([]);
      }}
      fullscreen={fullScreen}
    >
      <Carousel
        activeIndex={slideIndex}
        interval={null}
        controls={false}
        indicators={false}
      >
        <Carousel.Item>
          <Row>
            <Col>
              <DateField
                type={"month"}
                formik={formik}
                label={t("Backend.Post.Assistant.AiPlanner.Month")}
                valueName={"month"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
             <Timezones formik={formik} valueName="timezone"/>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <NumberField
                formik={formik}
                label={t("Backend.Post.Assistant.AiPlanner.PostingInterval")}
                valueName={"interval"}
                step="1"
              />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            {daysOfWeek.map((day, index) => (
              <Col md={4} key={index}>
                <Card>
                  <CardBody>{t(`Days.${day}`)}</CardBody>
                  <CardFooter>
                    <Button
                      className="me-2"
                      onClick={() => {
                        if (!formik.values.days.includes(day)) {
                          const updatedDays = [...formik.values.days, day];
                          formik.setFieldValue("days", updatedDays);
                        }
                      }}
                      disabled={
                        formik.values.days.includes(day) ||
                        formik.values.days.length >= formik.values.interval
                      }
                    >
                      <FontAwesomeIcon icon={faAdd} />
                    </Button>
                    {formik.values.days.includes(day) && (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const updatedDays = formik.values.days.filter(
                            (d) => d !== day
                          );
                          formik.setFieldValue("days", updatedDays);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    )}
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col className="text-center">
              <Button
                className="me-2"
                onClick={async () => {
                  setSlideIndex(4);
                  formik.setFieldValue("use_posts", true);
                }}
              >
                {t("Backend.Post.Assistant.AiPlanner.UsePosts")}
              </Button>
              <Button
                onClick={async () => {
                  setSlideIndex(4);
                  formik.setFieldValue("use_posts", false);
                }}
              >
                {t("Backend.Post.Assistant.AiPlanner.DontUsePosts")}
              </Button>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            {pillars.length > 0 ? (
              pillars.map((pillar, index) => {
                return (
                  <>
                    <Col md={4}>
                      <Card>
                        <CardHeader style={{ fontWeight: "bold" }}>
                          {pillar.pillar}
                        </CardHeader>
                        <CardBody>{pillar.description}</CardBody>
                        <CardFooter>
                          <Button
                            className="me-2"
                            onClick={() => {
                              if (
                                !selectedPillars.some((p) => p.id === pillar.id)
                              ) {
                                selectedPillars.push(pillar);
                                setSelectedPillars([...selectedPillars]);
                                formik.setFieldValue(
                                  "pillars",
                                  selectedPillars
                                );
                              }
                            }}
                            disabled={selectedPillars.some(
                              (p) => p.id === pillar.id
                            )}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </Button>
                          {selectedPillars.some((p) => p.id === pillar.id) && (
                            <Button
                              variant="danger"
                              onClick={() => {
                                const updatedPillars = selectedPillars.filter(
                                  (p) => p.id !== pillar.id
                                );
                                setSelectedPillars(updatedPillars);
                                formik.setFieldValue("pillars", updatedPillars);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </CardFooter>
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <Col>{t("Backend.Post.Assistant.AiPlanner.LoadingPillars")}</Col>
            )}
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      {t("Backend.Post.Assistant.AiPlanner.Summary.Month")}
                    </td>
                    <td>{formik.values.month}</td>
                  </tr>
                  <tr>
                    <td>
                      {t("Backend.Post.Assistant.AiPlanner.Summary.Timezone")}
                    </td>
                    <td>{formik.values.timezone}</td>
                  </tr>
                  <tr>
                    <td>
                      {t("Backend.Post.Assistant.AiPlanner.Summary.Week")}
                    </td>
                    <td>{formik.values.interval}</td>
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "Backend.Post.Assistant.AiPlanner.Summary.PostingDays"
                      )}
                    </td>
                    <td>
                      <ul>
                        {formik.values.days?.map((day, index) => {
                          return <li>{day}</li>;
                        })}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "Backend.Post.Assistant.AiPlanner.Summary.UseOwnPostsFirst"
                      )}
                    </td>
                    <td>{formik.values.use_posts ? "yes" : "no"}</td>
                  </tr>
                  <tr>
                    <td>
                      {t("Backend.Post.Assistant.AiPlanner.Summary.Pillars")}
                    </td>
                    <td>
                      <ul>
                        {formik.values.pillars?.map((pillar, index) => {
                          return <li>{pillar.pillar}</li>;
                        })}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          {schedules.length > 0 ?
          <PostCalendar
            schedules={schedules}
            setSchedules={setSchedules}
            customDeleteFunction={deletePlan}
            savePlan={savePlan}
            date={formik.values.month}
          />:
          "AI At work"}
        </Carousel.Item>
      </Carousel>
    </NewModal>
  );
};

export default AiPlanningAssistantView;
