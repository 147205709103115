import apiClient from "./client";
import binaryClient from "./binaryClient";
import ToastMessages from "../../web/src/ToastMessages";

let isWindowActive = true; // Default to true assuming the window starts in an active state

document.addEventListener("visibilitychange", function () {
  if (document.visibilityState === "visible") {
    isWindowActive = true;
  } else {
    isWindowActive = false;
  }
});

const getAll = async (endpoint, setData, dataField) => {
  if (typeof setData === "function" && typeof dataField === "string") {
    const response = await apiClient.get(endpoint);
    if (response.ok) {
      setData(response.data[dataField]);
    }
    return response;
  }
  return await apiClient.get(endpoint);
};

const justGet = async (endpoint, setShowPayModal) => {
  const response = await apiClient.get(endpoint);
  if (response.status === 402) {
    if (typeof setShowPayModal === "function") {
      setShowPayModal(true);
    }
  }
  return response;
};

const show = async (endpoint, id) => {
  const response = await apiClient.get(endpoint + id);
  if (response.status !== 200) {
    ToastMessages.NotFound();
    return null;
  }
  return response.data;
};

const createNew = async (endpoint, data, setShowPayModal, noToast = false) => {
  const response = await apiClient.post(endpoint, data);
  if (response.status === 200) {
    if (!noToast) {
      ToastMessages.SuccessfulSave();
    }
  } else if (response.status === 403) {
  } else if (response.status === 402) {
    if (typeof setShowPayModal === "function") {
      setShowPayModal(true);
    }
  } else {
    if (!noToast) {
      ToastMessages.Error();
    }
  }
  return response;
};

const search = async (endpoint, data, noToast = false) => {
  return createNew(endpoint, data, null, noToast);
};

const edit = async (endpoint, data) => {
  const response = await apiClient.put(endpoint, data);
  if (isWindowActive) {
    if (response.status === 200) {
      ToastMessages.SuccessfulSave();
    } else {
      ToastMessages.Error();
    }
  }
  return response;
};

const del = async (endpoint, id) => {
  const response = await apiClient.delete(endpoint + id);
  if (response.status === 200) {
    ToastMessages.SuccessfulSave();
  } else {
    ToastMessages.Error();
  }
  return response;
};

const getBinary = (endpoint) => {
  return binaryClient.get(endpoint);
};

const getWithData = (endpoint, data) => {
  return binaryClient.put(endpoint, data);
};

export default {
  getAll,
  show,
  createNew,
  edit,
  del,
  getBinary,
  search,
  justGet,
  getWithData,
};
